/**
 * Hint matches[] data
 *   - matches are ordered by tournament => stage => round => group => date
 *   - One tournament has only one block of current matches. Previously gaps were allowed.
 *
 * Performance
 *   Usually you shouldn't care about performance, but this screen is used and updated frequently.
 *   5 opened tournaments = 5 * 2000 iterations. In the future there will be much more matches.
 *   - filter 2.000 array items = 5ms
 *   - for 2.000 array items = 0ms
 *   - search* reduces 2.000 iterations to ~12
 */

import React from 'react';
// import Markdown from 'react-native-markdown-display';
import Markdown from 'react-markdown'
import Match from './Match.js';
import MatchHeader from './MatchHeader.js';
import MatchFooter from './MatchFooter.js';
import MatchSection from './MatchSection.js';
import Standings from './../standings/Standings.js';
import Title from './../Title.js';
import Touch from './../Touch.js';
import Lazy from './../Lazy.js';
import withErrorBoundary from './../common/withErrorBoundary.js';
import searchTournamentStart from './searchTournamentStart.js';
import searchTournamentEnd from './searchTournamentEnd.js';
import searchTournamentCurrentStart from './searchTournamentCurrentStart.js';
import searchTournamentCurrentEnd from './searchTournamentCurrentEnd.js';
import searchTournamentStageRoundStart from './searchTournamentStageRoundStart.js';
import searchTournamentStageRoundEnd from './searchTournamentStageRoundEnd.js';
import getEmojiByTournamentId from './../../helper/getEmojiByTournamentId.js';
import tableStyles from './../../styles/tableStyles.js';

const Matches = ({strings, tournamentId, matches, standings, highlighter, renamer, isTV, isStandingsDetails, cup, navigation, route}) => {
  console.log('Matches.render()');

  const [userChoice, setUserChoice] = React.useState();

  const tournamentStart = searchTournamentStart(matches, tournamentId);
  const tournamentEnd = searchTournamentEnd(matches, tournamentId);

  const currentStart = userChoice
    ? searchTournamentStageRoundStart(matches, tournamentId, userChoice?.stageId, userChoice?.roundId)
    : searchTournamentCurrentStart(matches, tournamentId);
  const currentEnd = userChoice
    ? searchTournamentStageRoundEnd(matches, tournamentId, userChoice?.stageId, userChoice?.roundId)
    : searchTournamentCurrentEnd(matches, tournamentId);

  if (tournamentStart === null || tournamentEnd === null || currentStart === null || currentEnd === null) {
    if (userChoice)                                                 // If there is a userChoice try to render again without userChoice
      setUserChoice();                                              // Could happen e. g. if the user views a round in season 2022 and the server switches to season 2023 where this round does not exist.
    return null;
  }

  const previousRound = () => {
    const previousMatch = matches[currentStart - 1];
    const {stageId, roundId} = previousMatch;
    setUserChoice({stageId, roundId});
  };

  const nextRound = () => {
    const nextMatch = matches[currentEnd + 1];
    const {stageId, roundId} = nextMatch;
    setUserChoice({stageId, roundId});
  };

  /* Meta */
  const firstMatch = matches[currentStart];
  const stageRoundName = strings.medium[firstMatch.stageStringId] || (firstMatch.rounds > 2 ? firstMatch.roundId + '. ' + strings.medium.matchday : '');
  document.title = getEmojiByTournamentId(tournamentId).toString();
  if (tournamentId === 1) document.title += ` WM 2022: Spielplan & Live Ergebnisse`;
  else if (tournamentId === 15) document.title += ` EM 2024: Spielplan & Live Ergebnisse`;
  else if (tournamentId === 55) document.title += ` Frauen EM 2022: Spielplan & Live Ergebnisse`;
  else document.title += ` ${strings.medium['tournament' + tournamentId]}: Spielplan, Live Ergebnisse & Tabelle | ${stageRoundName} | 2022/2023`;
  document.head.children.description.content =
    'Fußball Ergebnisse, Spielplan, Tabellen und alle TV Übertragungen'
    + ([100, 150, 200, 210, 300].includes(tournamentId) ? ' zum ' : ' zur ')
    + strings.medium['tournament' + tournamentId];

  return (
    <div id="matches" className="safe-area-desktop-small">
      <Lazy>
        {cup ? <div style={tableStyles.footerViewEmpty}/> : <Title>{strings.medium['tournament' + tournamentId]}</Title>}
        {
          userChoice && (
            <Touch style={styles.undo} onPress={() => setUserChoice()} iosComponent="TouchableOpacity">
              {/* <Icon androidName="undo" iosName="arrow-undo-outline" androidSize={50} iosSize={42} color={colors.primary}/> */}
            </Touch>
          )
        }
        {
          (() => {
            const rows = [];
            for (let i = currentStart; i <= currentEnd; i++) {
              const firstCurrentMatch = i === currentStart;
              const lastCurrentMatch = i === currentEnd;
              const match = matches[i];
              if (!match) continue;
              const previousMatch = matches[i-1];
              const nextMatch = matches[i+1];
              const tournamentWillChange = nextMatch?.tournamentId !== match.tournamentId;
              const tournamentDidChange = previousMatch?.tournamentId !== match.tournamentId; // Hint: You also have to check tournamentId. Reason: The match before could be t101s1. The current match could be t102s1. So no stage change = bad.
              const stageWillChange = nextMatch?.stageId !== match.stageId;
              const stageDidChange = previousMatch?.stageId !== match.stageId;
              const roundWillChange = nextMatch?.roundId !== match.roundId;
              const roundDidChange = previousMatch?.roundId !== match.roundId;

              const startAt = new Date(match.startAt);
              const previousStartAt = new Date(previousMatch?.startAt);
              const dayDidChange = startAt.getDate() !== previousStartAt.getDate() || startAt.getMonth() !== previousStartAt.getMonth();

              /* MatchHeader: Round 34 */
              if (tournamentDidChange || stageDidChange || roundDidChange)
                rows.push(                                          // Show arrow only 1. At the top (firstCurrentMatch) and 2. There are previous matches (!tournamentDidChange)
                  <MatchHeader
                    key={'MatchHeader' + (firstCurrentMatch ? 'First' : match.matchId)}
                    match={match}
                    strings={strings}
                    arrow={firstCurrentMatch && !tournamentDidChange}
                    onPress={previousRound} onRoundPress={
                      () => navigation.navigate('RoundPicker', {tournamentId, parentScreenName: route.name})
                    }
                  />
                );

              /* MatchSection: Tuesday 22.3. */
              if (dayDidChange || tournamentDidChange || stageDidChange || roundDidChange) // Check of dayDidChange is not enough. Sometimes there are two stages on one day. See World cup 2014/2018.
                rows.push(<MatchSection key={'MatchSection' + match.matchId} match={match} startAt={startAt} strings={strings} first={tournamentDidChange || stageDidChange || roundDidChange}/>);

              /* MatchLine: ----- */
              if (!dayDidChange)
                rows.push(<div key={'MatchLine' + match.matchId} style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>);

              /* Match: 15:30 Dortmund -:- Bayern Sky */            // Try to avoid adding something below this line!
              rows.push(<Match key={match.matchId} match={match} strings={strings} last={tournamentWillChange || stageWillChange || roundWillChange} isTV={isTV} highlighter={highlighter} renamer={renamer} navigation={navigation}/>);

              if (tournamentWillChange || stageWillChange || roundWillChange)
                rows.push(<MatchFooter key={'MatchFooter' + (lastCurrentMatch ? 'Last' : match.matchId)} strings={strings} isTV={isTV} lastCurrentMatch={lastCurrentMatch} tournamentWillChange={tournamentWillChange} onPress={nextRound}/>);

              /**
               * Standings
               * - Standings should appear at the end of a current stage
               * - But the end of a stage is usually not a match with current=true
               */

              if (tournamentWillChange || stageWillChange || lastCurrentMatch)
                rows.push(<Standings key={'Standings' + match.stageId} standings={standings} isDetails={isStandingsDetails} strings={strings} highlighter={highlighter} renamer={renamer} tournamentId={tournamentId} stageId={match.stageId} navigation={navigation}/>);
            }
            return rows;
          })()
        }
        {(() => {
          const markdown = cup
            ? strings.medium.cupFixturesMarkdown
            : strings.medium[`tournament${tournamentId}Markdown`];
          if (markdown) return (
            <>
              <div style={tableStyles.headerViewEmpty}/>
              <div style={{...tableStyles.rowViewFirstLast, ...styles.info}}>
                <Markdown>{markdown}</Markdown>
              </div>
            </>
          );
        })()}
        <div style={tableStyles.footerViewEmpty}/>
      </Lazy>
    </div>
  );
};

const styles = {
  undo: {
    position: 'absolute',
    zIndex: 10,
    top: 8,
    right: 8,
    paddingLeft: 16,
  },
  info: {
    display: 'block',
    height: 'auto',
    paddingTop: 8,
    paddingBottom: 8,
  },
};

export default withErrorBoundary(Matches, 'Matches');
