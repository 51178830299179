/** Do not change viewBox. If you change 24 it doesn't look good anymore. */

import React from 'react';

const chevronLeft = 'M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z';
const chevronRight = 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z';
const chevronUp = 'M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z';
const chevronDown = 'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z';
const menuDown = 'M7,10L12,15L17,10H7Z';
const genderFemale = 'M12,4A6,6 0 0,1 18,10C18,12.97 15.84,15.44 13,15.92V18H15V20H13V22H11V20H9V18H11V15.92C8.16,15.44 6,12.97 6,10A6,6 0 0,1 12,4M12,6A4,4 0 0,0 8,10A4,4 0 0,0 12,14A4,4 0 0,0 16,10A4,4 0 0,0 12,6Z';
const soccer = 'M16.93 17.12L16.13 15.76L17.59 11.39L19 10.92L20 11.67C20 11.7 20 11.75 20 11.81C20 11.88 20.03 11.94 20.03 12C20.03 13.97 19.37 15.71 18.06 17.21L16.93 17.12M9.75 15L8.38 10.97L12 8.43L15.62 10.97L14.25 15H9.75M12 20.03C11.12 20.03 10.29 19.89 9.5 19.61L8.81 18.1L9.47 17H14.58L15.19 18.1L14.5 19.61C13.71 19.89 12.88 20.03 12 20.03M5.94 17.21C5.41 16.59 4.95 15.76 4.56 14.75C4.17 13.73 3.97 12.81 3.97 12C3.97 11.94 4 11.88 4 11.81C4 11.75 4 11.7 4 11.67L5 10.92L6.41 11.39L7.87 15.76L7.07 17.12L5.94 17.21M11 5.29V6.69L7 9.46L5.66 9.04L5.24 7.68C5.68 7 6.33 6.32 7.19 5.66S8.87 4.57 9.65 4.35L11 5.29M14.35 4.35C15.13 4.57 15.95 5 16.81 5.66C17.67 6.32 18.32 7 18.76 7.68L18.34 9.04L17 9.47L13 6.7V5.29L14.35 4.35M4.93 4.93C3 6.89 2 9.25 2 12S3 17.11 4.93 19.07 9.25 22 12 22 17.11 21 19.07 19.07 22 14.75 22 12 21 6.89 19.07 4.93 14.75 2 12 2 6.89 3 4.93 4.93Z';
const checkCircle = 'M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z';

const Icon = ({androidName, iosName, /* Optional: */ size, androidSize, iosSize, color, androidColor, iosColor, style, androidStyle, iosStyle}) => {
  console.log('Icon.render()');

  const name = androidName || iosName;
  const size_ = androidSize || iosSize || size || 24;
  const color_ = androidColor || iosColor || color || 'currentColor';

  return (
    <svg style={{width: size_, height: size_, ...style}} viewBox='0 0 24 24'>
        <path fill={color_} d={
          name === 'chevron-left' ? chevronLeft :
          name === 'chevron-right' ? chevronRight :
          name === 'chevron-up' ? chevronUp :
          name === 'chevron-down' ? chevronDown :
          name === 'menu-down' ? menuDown :
          name === 'gender-female' ? genderFemale :
          name === 'soccer' ? soccer :
          name === 'check-circle' ? checkCircle :
          null
        }/>
    </svg>
  );
};

export default Icon;
