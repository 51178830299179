/**
 * Multiple designs tested:
 * 1. Small (12px) circles look best
 * 2. Arrows and abbreviations (W, L, D) inside the circle look better than thumb icons
 * 3. Abbreviations are easier to understand than arrows
 */

import React from 'react';
import colors from './../../colors.js';

const MatchResultIcon = ({match, teamId, strings}) => {
  console.log('MatchResultIcon.render()');
  if      (match.homeTeamId === teamId && match.homeGoals > match.awayGoals) return <div style={styles.won}><div style={styles.text} /*allowFontScaling={false}*/>{strings.shortcut.won}</div></div>;
  else if (match.homeTeamId === teamId && match.homeGoals < match.awayGoals) return <div style={styles.lost}><div style={styles.text} /*allowFontScaling={false}*/>{strings.shortcut.lost}</div></div>;
  else if (match.awayTeamId === teamId && match.homeGoals < match.awayGoals) return <div style={styles.won}><div style={styles.text} /*allowFontScaling={false}*/>{strings.shortcut.won}</div></div>;
  else if (match.awayTeamId === teamId && match.homeGoals > match.awayGoals) return <div style={styles.lost}><div style={styles.text} /*allowFontScaling={false}*/>{strings.shortcut.lost}</div></div>;
  else                                                                       return <div style={styles.drawn}><div style={styles.text} /*allowFontScaling={false}*/>{strings.shortcut.drawn}</div></div>;
};

const styles = {
  drawn: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 12,
    width: 12,
    borderRadius: 40,
    marginRight: 2,
    backgroundColor: '#00000055',
  },
  won: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 12,
    width: 12,
    borderRadius: 40,
    marginRight: 2,
    backgroundColor: colors.green,
  },
  lost: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 12,
    width: 12,
    borderRadius: 40,
    marginRight: 2,
    backgroundColor: colors.red,
  },
  text: {
    marginTop: 0,
    paddingLeft: 1,
    fontSize: 10,
    fontWeight: 'bold',
    color: 'white',
  },
};

export default MatchResultIcon;
