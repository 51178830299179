import React from 'react';
import {Link} from 'react-router-dom';
import Title from './Title.js';
import MatchesByTournament from './match/MatchesByTournament.js';
import NextMatchesCountdown from './common/NextMatchesCountdown.js';
import RowViewChevron from './common/RowViewChevron.js';
import withErrorBoundary from './common/withErrorBoundary.js';
import isMatchActive from './../helper/isMatchActive.js';
import tableStyles from './../styles/tableStyles.js';

const Live = ({matches, strings}) => {
  console.log('Live.render()');

  /* Head Title */
  document.title = 'Fußball LIVE Heute';

  /* Head Meta Description */
  document.head.children.description.content = `Jetzt LIVE am ${new Date().toLocaleDateString('de-DE')}. Alle TV Sender / Übertragungen, alle Spiele, alle live Ergebnisse und Ligen wie Bundesliga und Co.`;

  const liveMatches = matches.filter((match) => isMatchActive(match.status))
  return (
    <div id="live" className="safe-area-desktop-small">
      <Title>LIVE</Title>
      <MatchesByTournament matches={liveMatches} strings={strings}/>
      {
        liveMatches.length === 0 && (
          <>
            <div style={tableStyles.rowViewFirstLast}>
              <div style={styles.rowViewText}>
                Momentan keine Spiele
              </div>
            </div>
          </>
        )
      }
      <div style={tableStyles.footerViewEmpty}/>

      <NextMatchesCountdown matches={matches}/>

      <Link to="/heute" style={tableStyles.rowViewFirstLast}>
        <div style={styles.rowViewText}>
          Alle Spiele HEUTE anzeigen
        </div>
        <RowViewChevron/>
      </Link>
      <div style={tableStyles.footerViewEmpty}/>
    </div>
  );
};

const styles = {
  rowViewText: {
    flexGrow: 1,
    textAlign: 'center',
  },
}

export default withErrorBoundary(Live, 'Live');
