/**
 * Get the first match of a tournamentId, stageId, roundId
 */

const searchTournamentStageRoundStart = (matches, tournamentId, stageId, roundId) => { try {
  return treeSearch(matches, tournamentId, stageId, roundId, 0, matches.length-1, 0);
} catch (e) { return null; }};

const treeSearch = (matches, tournamentId, stageId, roundId, lower, upper, iterations) => {
  iterations++;                                                     // Prevent unknown endless loops
  if (iterations === 30) return null;
  if (lower > upper) return null;                                   // Not found

  const median = lower + Math.round((upper - lower) / 2);
  const currentMatch = matches[median];
  const previousMatch = matches[median-1];

  if      (tournamentId < currentMatch.tournamentId) return treeSearch(matches, tournamentId, stageId, roundId, lower, median - 1, iterations); // Go left
  else if (tournamentId > currentMatch.tournamentId) return treeSearch(matches, tournamentId, stageId, roundId, median + 1, upper, iterations); // Go right
  else if (tournamentId===currentMatch.tournamentId) {              // You need to check the stageId
    if      (stageId < currentMatch.stageId) return treeSearch(matches, tournamentId, stageId, roundId, lower, median - 1, iterations);         // Go left
    else if (stageId > currentMatch.stageId) return treeSearch(matches, tournamentId, stageId, roundId, median + 1, upper, iterations);         // Go right
    else if (stageId===currentMatch.stageId) {                      // You need to check the roundId
      if      (roundId < currentMatch.roundId) return treeSearch(matches, tournamentId, stageId, roundId, lower, median - 1, iterations);       // Go left
      else if (roundId > currentMatch.roundId) return treeSearch(matches, tournamentId, stageId, roundId, median + 1, upper, iterations);       // Go right
      else if (roundId===currentMatch.roundId && (roundId!==previousMatch?.roundId || stageId!==previousMatch?.stageId || tournamentId!==previousMatch?.tournamentId)) return median; // The "?." is just to not crash on the first and last match of matches
      else if (roundId===currentMatch.roundId) return treeSearch(matches, tournamentId, stageId, roundId, lower, median - 1, iterations);       // Go left
    }
  }
};

export default searchTournamentStageRoundStart;
