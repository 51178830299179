/**
 * Warning: it is important to set a default color in text
 * Otherwise the text disappears (even in production!)
 * E. g. go from ended (color grey) to postponed (no color set)
 * The problem:
 * If there is this       <Text style={[styles.noColor, styles.withColor]}>x</Text>
 * No you change to this: <Text style={[styles.noColor]}>x</Text>
 * It disappears.
 * styles.noColor needs a default color!
 */

import React from 'react';
import isMatchEnded from './../../helper/isMatchEnded.js';
import colors from './../../colors.js';

const MatchLeft = ({match, strings, detailsTeamId}) => {
  console.log('MatchLeft.render()');

  if (match.minute) return <div /*numberOfLines={1}*/ style={styles.text}>{match.minute}'</div>;

  if (detailsTeamId && (isMatchEnded(match.status) || match.status === 'scheduled')) {
    const startAt = new Date(match.startAt);
    return <div /*maxFontSizeMultiplier={1.3}*/ style={styles.text}>{startAt.getDate()}.{(startAt.getMonth() + 1)}</div>;
  }

  switch (match.status) {
    case 'scheduled': {
      const startAt = new Date(match.startAt);
      const minutes = startAt.getMinutes();
      const hours = startAt.getHours();
      const time = ((hours < 10 ? '0' : '') + hours) + ':' + ((minutes < 10 ? '0' : '') + minutes);
      return <div /*numberOfLines={1}*/ style={styles.text} /*maxFontSizeMultiplier={1.3}*/>{time}</div>;
    }
    case 'firstHalf':
    case 'halfTime':
    case 'secondHalf':
    case 'extraTime':
    case 'penaltyShootout':
    case 'postponed':
    case 'canceled':
    case 'delayed':
    case 'interrupted':
    case 'suspended':
    case 'abandoned': return <div /*numberOfLines={1}*/ style={{...styles.text, ...styles.small}} /*maxFontSizeMultiplier={1.5}*/>{strings.medium[match.status]}</div>;
    case 'ended': return <div /*numberOfLines={1}*/ style={{...styles.text, ...styles.ended}} /*maxFontSizeMultiplier={1.5}*/>{strings.medium.end}</div>;
    case 'endedExtraTime': return <div /*numberOfLines={2}*/ style={{...styles.text, ...styles.small, ...styles.ended}} /*maxFontSizeMultiplier={1.2}*/>{strings.medium.end + '\n+' + strings.medium.extraTime}</div>;
    case 'endedPenaltyShootout': return <div /*numberOfLines={2}*/ style={{...styles.text, ...styles.small, ...styles.ended}} /*maxFontSizeMultiplier={1.2}*/>{strings.medium.end + '\n+' + strings.medium.penaltyShootout}</div>;
    default: return <div style={styles.text}/>;
  }
};

const styles = {
  text: {
    flexBasis: 50,
    flexShrink: 0,
    flexGrow: 0.4,
    fontSize: 14,
    color: colors.textPrimary,                                      // WARNING: This is necessary. Otherwise the text disappears. See above.
    display: 'initial',                                             // It's not allowed to use flex
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  small: {
    fontSize: 12,
  },
  ended: {
    color: colors.textSecondary,
  },
};

export default MatchLeft;
