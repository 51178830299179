import React from 'react';
import Tab from './Tab.js';
import tournamentPaths from './../../helper/tournamentPaths.js';
import tournamentNames from './../../helper/tournamentNames.js';

const TabBar = () => {
  console.log('TabBar.render()');
  return (
    <nav id="tabBar" style={styles.container} className="safe-area">
      <Tab to={tournamentPaths[101]} emoji="🇩🇪">{tournamentNames[101]}</Tab>
      <Tab to={tournamentPaths[102]} emoji="🇩🇪">{tournamentNames[102]}</Tab>
      <Tab to={tournamentPaths[103]} emoji="🇩🇪">{tournamentNames[103]}</Tab>
      <Tab to={tournamentPaths[100]} emoji="🇩🇪">{tournamentNames[100]}</Tab>
      <Tab to={tournamentPaths[15]} emoji="🇪🇺">{tournamentNames[15]}</Tab>
      <Tab to={tournamentPaths[10]} emoji="🇪🇺">{tournamentNames[10]}</Tab>
      <Tab to={tournamentPaths[11]} emoji="🇪🇺">{tournamentNames[11]}</Tab>
      <Tab to={tournamentPaths[12]} emoji="🇪🇺">{tournamentNames[12]}</Tab>
      <Tab to={tournamentPaths[201]} emoji="🏴󠁧󠁢󠁥󠁮󠁧󠁿">{tournamentNames[201]}</Tab>
      <Tab to={tournamentPaths[151]} emoji="♀">{tournamentNames[151]}</Tab>
      <Tab to="/alle-ligen" emoji="...">Mehr</Tab>
    </nav>
  );
};

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#f44336',
    color: 'white',
  },
}

export default TabBar;
