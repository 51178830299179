/**
 * 1. Set device to 360px
 * 2. Set goalsFor, goalsAgainst, points with 3 digits
 * 3. Set flexBasis of team column to 1000
 * 4. Set flexBasis of any other column. They have to barely fit, than add 2px
 * 5. Sum all flexBasis and outer margin/padding. But not team. 8+8+26+27+23+20+20+24+28+5+28+27+30+8+8=290
 * 6. Substract from 360. 360-290=70
 * 7. Set team flexBasis to 70
 * 8. Hint: Fore devices below 360px remove result column
 */

import colors from './../../colors.js';

const styles = {
  container: {
    display: 'flex',                                                // Web
    flexDirection: 'row',
    alignItems: 'center',
    height: 34,                                                     // iOS 15 | table row height default 44px
    marginLeft: 8,                                                  // iOS 15 | default 16px
    marginRight: 8,                                                 // iOS 15 | default 16px
    paddingLeft: 8,                                                 // iOS 15 | default 16px
    paddingRight: 8,                                                // iOS 15 | default 16px
    backgroundColor: 'white',                                       // iOS 15 | table row backgroundColor
  },
  first: {
    borderTopLeftRadius: 10,                                        // iOS 15 | table round
    borderTopRightRadius: 10,                                       // iOS 15 | table round
  },
  last: {
    borderBottomLeftRadius: 10,                                     // iOS 15 | table round
    borderBottomRightRadius: 10,                                    // iOS 15 | table round
  },

  resultIcon: {
    flexBasis: 26,
    flexShrink: 0,
    flexGrow: 0,
  },
  resultText: {
    flexBasis: 26,
    flexShrink: 0,
    flexGrow: 0,
    fontSize: 12,
    color: colors.textSecondary,
  },
  rank: {
    flexBasis: 27,
    flexShrink: 0,
    flexGrow: 0,
    paddingRight: 2,                                                // % Does not work Section and Standing rows differ
    textAlign: 'right',
    fontSize: 14,
    color: colors.textPrimary,
    fontWeight: 'bold',
  },
  team: {
    flexDirection: 'row',
    flexBasis: 70,
    flexShrink: 1,
    flexGrow: 40,
    paddingLeft: 2,
    fontSize: 14,
    color: colors.textPrimary,
  },
  played: {
    flexBasis: 23,
    flexShrink: 0,
    flexGrow: 0,
    paddingRight: 2,
    borderRightWidth: 1,
    borderRightColor: colors.line,
    textAlign: 'right',
    fontSize: 14,
    color: colors.textPrimary,
    flexWrap: 'nowrap',
    overflow: 'visible',
  },
  won: {
    flexBasis: 20,
    flexShrink: 0,
    flexGrow: 0,
    alignSelf: 'center',
    textAlign: 'right',
    fontSize: 14,
    color: colors.textSecondary,
  },
  drawn: {
    flexBasis: 20,
    flexShrink: 0,
    flexGrow: 0,
    textAlign: 'right',
    fontSize: 14,
    color: colors.textSecondary,
  },
  lost: {
    flexBasis: 24,
    flexShrink: 0,
    flexGrow: 0,
    paddingRight: 4,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: colors.line,
    textAlign: 'right',
    fontSize: 14,
    color: colors.textSecondary,
  },
  goalsFor: {
    flexBasis: 28,
    flexShrink: 0,
    flexGrow: 0,
    textAlign: 'right',
    fontSize: 14,
    color: colors.textSecondary,
  },
  goalsSeparator: {
    flexBasis: 5,
    flexShrink: 0,
    flexGrow: 0,
    textAlign: 'center',
    fontSize: 14,
    color: colors.textSecondary,
  },
  goalsAgainst: {
    flexBasis: 28,
    flexShrink: 0,
    flexGrow: 0,
    textAlign: 'left',
    fontSize: 14,
    color: colors.textSecondary,
  },
  goalDifference: {
    flexBasis: 27,
    flexShrink: 1,
    flexGrow: 0,
    paddingRight: 2,
    textAlign: 'right',
    fontSize: 14,
    color: colors.textPrimary,
  },
  points: {
    flexBasis: 30,
    flexShrink: 0,
    flexGrow: 0,
    borderLeftWidth: StyleSheet.hairlineWidth,
    borderLeftColor: colors.line,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
};

export default styles;
