import React from 'react';
import {NavLink} from 'react-router-dom';
import './tab.css';

const Tab = ({children, to, emoji}) => {
  console.log('Tab.render()');
  return (
    <NavLink to={to} style={({isActive}) => isActive ? {...styles.link, ...styles.linkActive} : styles.link} id={'tab-' + to.substring(1)}>
      <div style={styles.emoji}>{emoji}</div>
      <div style={styles.text}>{children}</div>
    </NavLink>
  );
};

const styles = {
  link: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 1,
    flexShrink: 1,
    flexGrow: 1,
    justifyContent: 'center',
    height: 44,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    overflow: 'hidden',                                             // This is necessary for "textOverflow: ellipses" in styles.text to work
  },
  linkActive: {
    backgroundColor: '#00000033',
  },
  emoji: {
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: 12,
    paddingLeft: 2,
    paddingRight: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}

export default Tab;
