const getRoundName = (roundId, rounds, strings) => {
  if (rounds === 1) return '';
  else if (rounds === 2) {
    if (roundId === 1) return strings.medium.firstLeg;
    else return strings.medium.secondLeg;
  }
  else return `${roundId}. ${strings.medium.matchday}`;
};

export default getRoundName;
