const getEmojiByTournamentId = (tournamentId) => {
  if (tournamentId >= 1 && tournamentId <= 9) return '🌍';
  if (tournamentId >= 10 && tournamentId <= 99) return '🇪🇺';
  if (tournamentId >= 100 && tournamentId <= 199) return '🇩🇪';
  if (tournamentId >= 200 && tournamentId <= 299) return '🏴󠁧󠁢󠁥󠁮󠁧󠁿';
  if (tournamentId >= 300 && tournamentId <= 399) return '🏴󠁧󠁢󠁳󠁣󠁴󠁿';
  if (tournamentId >= 400 && tournamentId <= 499) return '🇪🇸';
  if (tournamentId >= 500 && tournamentId <= 599) return '🇮🇹';
  if (tournamentId >= 600 && tournamentId <= 699) return '🇫🇷';
};

export default getEmojiByTournamentId;
