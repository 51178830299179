import React from 'react';

const Lazy = ({children, placeholder}) => {
  console.log('Lazy.render()');
  const [firstRender, setFirstRender] = React.useState(true);
  React.useEffect(() => {
    const timeoutId = setTimeout(() => setFirstRender(false), 1);   // Prevent batching in iOS
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    firstRender
    ? placeholder || null
    : children || null                                              // Prevents a crash if there are no children
  );
};

export default Lazy;
