import React from 'react';
import {Link} from 'react-router-dom';
import MatchLeft from './MatchLeft.js';
import MatchTeam from './MatchTeam.js';
import MatchGoals from './MatchGoals.js';
import MatchRight from './MatchRight.js';
import createURL from './../../helper/createURL.js';
import tableStyles from './../../styles/tableStyles.js';

const Match = ({match, strings, first, last, detailsTeamId}) => {
  console.log('Match.render()');
  const url = createURL(`${strings.small.teams[match.homeTeamId] || strings.medium.teams[match.homeTeamId]}-gegen-${strings.small.teams[match.awayTeamId] || strings.medium.teams[match.awayTeamId]}-${match.matchId}`);
  return (
    React.createElement((match.homeTeamId && match.awayTeamId) ? Link : 'div', 
      {
        to: '/' + url,
        style: first && last ? tableStyles.rowViewFirstLast : first ? tableStyles.rowViewFirst : last ? tableStyles.rowViewLast : tableStyles.rowView
      },
      ...[
        <MatchLeft match={match} strings={strings} detailsTeamId={detailsTeamId}/>,
        <MatchTeam match={match} strings={strings} team="home"/>,
        <MatchGoals match={match}/>,
        <MatchTeam match={match} strings={strings} team="away"/>,
        <MatchRight match={match} strings={strings} detailsTeamId={detailsTeamId}/>,
      ]
    )
  );
};

/**
 * Performance optimization
 * - Render Match only if something changed
 * - useMemo did not work
 */
class MatchSCU extends React.Component{
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.match.changedAt !== this.props.match.changedAt ||
      nextProps.strings         !== this.props.strings ||
      nextProps.first           !== this.props.first ||
      nextProps.last            !== this.props.last ||
      nextProps.detailsTeamId   !== this.props.detailsTeamId
    );
  }
  render() {
    console.log('MatchSCU.render()');
    return <Match {...this.props}/>;
  }
}

export default MatchSCU;
