import React from 'react';
import Title from './Title.js';
import withErrorBoundary from './common/withErrorBoundary.js';
import tableStyles from './../styles/tableStyles.js';

const SiteNotice = () => {
  console.log('SiteNotice.render()');

  /* Meta */
  document.title = 'Impressum';
  document.head.children.description.content = 'Impressum von www.fussball-ergebnis.de';

  return (
    <div className="safe-area-desktop-small">
      <Title>Impressum</Title>

      <div style={tableStyles.headerView}><div style={tableStyles.headerText}>Angaben gemäß § 5 TMG</div></div>
      <div style={{...tableStyles.rowViewFirst, ...styles.row}}><div style={tableStyles.rowText}>Rafael Waclawiec</div></div>
      <div style={{...tableStyles.rowView, ...styles.row}}><div style={tableStyles.rowText}>Youcorp</div></div>
      <div style={{...tableStyles.rowView, ...styles.row}}><div style={tableStyles.rowText}>Klausingring 27</div></div>
      <div style={{...tableStyles.rowView, ...styles.row}}><div style={tableStyles.rowText}>13627 Berlin</div></div>
      <div style={{...tableStyles.rowViewLast, ...styles.row}}><div style={tableStyles.rowText}>Deutschland</div></div>
      <div style={tableStyles.footerViewEmpty}></div>

      <div style={tableStyles.headerView}><div style={tableStyles.headerText}>USt-ID (§27 a Umsatzsteuergesetz)</div></div>
      <div style={{...tableStyles.rowViewFirstLast, ...styles.row}}><div style={tableStyles.rowText}>DE304365232</div></div>
      <div style={tableStyles.footerViewEmpty}></div>

      <div style={tableStyles.headerView}><div style={tableStyles.headerText}>Kontaktdaten</div></div>
      <div style={{...tableStyles.rowViewFirst, ...styles.row}}><div style={tableStyles.rowText}>Telefon: +491707103637</div></div>
      <div style={{...tableStyles.rowViewLast, ...styles.row}}><div style={tableStyles.rowText}>E-Mail: kontakt@fussball-ergebnis.de</div></div>
      <div style={tableStyles.footerViewEmpty}></div>
    </div>
  );
};

const styles = {
  row: {
    height: 28,
  },
}

export default withErrorBoundary(SiteNotice, 'SiteNotice');
