import React from 'react';
import Match from './../match/Match';
import Touch from './../Touch';
import tableStyles from './../../styles/tableStyles';
import colors from './../../colors';

const TeamNextMatches = ({teamId, teamStatistics, strings, renamer, navigation}) => {
  console.log('TeamNextMatches.render()');
  const [more, setMore] = React.useState(false);
  return (
    <>
      <div style={tableStyles.headerView}>
        <div style={tableStyles.headerText}>{strings.medium.nextMatches}</div>
      </div>
      {
        teamStatistics.nextMatches.slice(0, more ? 100 : 5).map((match, index, nextMatches) => {
          return (
            <React.Fragment key={match.matchId}>
              <Match match={match} strings={strings} detailsTeamId={teamId} first={index === 0} last={index === nextMatches.length-1}/>
              {index !== nextMatches.length-1 && <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>}
            </React.Fragment>
          );
        })
      }
      <div style={{...tableStyles.footerView, ...styles.footer}}>
        <Touch style={styles.moreTouch} onPress={() => setMore(!more)}>
          <div style={styles.moreText}>{more ? strings.medium.less : strings.medium.more2}</div>
        </Touch>
      </div>
    </>
  );
};

const styles = {
  footer: {
    justifyContent: 'center',
  },
  moreTouch: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  moreText: {
    color: colors.primary,
  },
};

export default TeamNextMatches;
