/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {Link} from 'react-router-dom';
import useWindowDimensions from './../../helper/useWindowDimensions.js';
import NavBarButton from './NavBarButton.js';

const NavBar = () => {
  console.log('NavBar.render()');
  const {width} = useWindowDimensions();
  return (
    <header id="navBar" style={styles.container} className="safe-area">
      <Link to="/">
        <img src="/logo192.png" alt="fussball-ergebnis-de-logo" style={styles.logo} width={70} height={70}/>
      </Link>
      <Link to="/">
        <h1 style={width <= 420 ? styles.h1Small : styles.h1} className="span">FUSSBALL-<br/>ERGEBNIS.DE</h1>
      </Link>
      <div style={styles.placeholder}/>
      <div style={styles.right} className="remove319">
        <NavBarButton to="/heute">Heute</NavBarButton>
        <NavBarButton to="/morgen">Morgen</NavBarButton>
      </div>
      <div style={styles.right} className="remove389">
        <NavBarButton to="/gestern">Gestern</NavBarButton>
      </div>
    </header>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxHeight: 100,
    backgroundColor: '#f44336',
  },
  logo: {
    marginLeft: -10,
  },
  h1: {
    fontSize: 24,
    fontWeight: '800',
    letterSpacing: 1,
    color: '#FFFFFFF0',
    textShadow: '2px 2px #ff0000',
  },
  h1Small: {
    fontSize: 16,
    fontWeight: '800',
    letterSpacing: 1,
    color: '#FFFFFFF0',
    textShadow: '2px 2px #ff0000',
  },
  placeholder: {
    flexGrow: 1,
  },
  right: {
    alignSelf: 'flex-start',
    marginTop: 6,
    marginLeft: 4
  },
}

export default NavBar;
