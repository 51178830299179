import React from 'react';
import tableStyles from './../../styles/tableStyles.js';

const StandingHeader = ({children}) => {
  console.log('StandingHeader.render()');
  return (
    <div style={tableStyles.headerView}>
      <div style={tableStyles.headerText}>{children}</div>
    </div>
  );
};

export default StandingHeader;
