import React from 'react';

const Title = ({children}) => {
  console.log('Title.render()');
  return (
    <div id="title">
      <h1 style={styles.h1}>{children}</h1>
    </div>
  );
};

const styles = {
  h1: {
    marginTop: 20,
    marginBottom: 16,
    fontSize: 17,
    textAlign: 'center',
  },
}

export default Title;
