import React from 'react';
import useInterval from './../../helper/useInterval.js';

const Countdown = ({date, style}) => {
  console.log('Countdown.render()');

  let [now, setNow] = React.useState(new Date());

  useInterval(() => {
    setNow(new Date());
  }, 1000);

  const then = new Date(date);
  const diff = Math.floor((then - now) / 1000);
  const days = Math.floor(diff / (60 * 60 * 24));
  const hours = Math.floor(diff / (60 * 60)) % 24;
  const minutes = Math.floor(diff / 60) % 60;
  const seconds = diff % 60;

  const daysString = days > 0 ? days.toString() + (days === 1 ? ' Tag' : ' Tage') : '';
  const hoursString = hours > 0 ? hours.toString() + ' Std.' : '';
  const minutesString = hours > 0 || minutes > 0 ? minutes.toString() + ' Min.' : '';
  const secondsString = seconds.toString() + ' Sek.';

  return (
    <span style={style}>{daysString} {hoursString} {minutesString} {secondsString}</span>
  );
};

export default Countdown;
