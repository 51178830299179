import React from 'react';
import MatchResultIcon from './../match/MatchResultIcon.js';
import isMatchEnded from './../../helper/isMatchEnded.js';

const DetailsComparisonLastMatches = ({matches, teamId, strings}) => {
  console.log('DetailsComparisonLastMatches.render()');
  return (
    <div style={styles.comparisonLastMatches}>
      {(() => {
        return matches
          .filter(({status}) => isMatchEnded(status))
          .slice(-7)
          .map((match, i) => <MatchResultIcon match={match} teamId={teamId} strings={strings} key={match.matchId}/>);
      })()}
    </div>
  );
};

const styles = {
  comparisonLastMatches: {
    display: 'flex',
    flexBasis: 1,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
};

export default DetailsComparisonLastMatches;
