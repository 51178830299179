import isToday from './isToday';
import isYesterday from './isYesterday';
import isTomorrow from './isTomorrow';
import isDate from './isDate';

const getDatePath = (date) => {
  if (!isDate(date)) date = new Date(date);                         // If date is a string or int, try to convert it to a date object
  if (!isDate(date)) return '/';                                    // If date is still not a date object, return the root url
  if (isToday(date)) return '/heute';
  if (isYesterday(date)) return '/gestern';
  if (isTomorrow(date)) return '/morgen';
  return `/${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
}

export default getDatePath;
