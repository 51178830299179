import React from 'react';
import {NavLink} from 'react-router-dom';
import colors from './../../colors.js';

const NavBarButton = ({children, to}) => {
  console.log('NavBarButton.render()');
  return (
    <NavLink to={to} style={({isActive}) => isActive ? {...styles.container, ...styles.linkActive} : styles.container}>
      {children}
    </NavLink>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 75,
    height: 26,
    marginBottom: 4,
    borderRadius: 6,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    fontSize: 14,
    backgroundColor: colors.primary,
    color: colors.textPrimaryDark,
  },
  linkActive: {
    backgroundColor: '#00000033',
  },
}

export default NavBarButton;
