import React from 'react';
import colors from '../../colors';
import tableStyles from './../../styles/tableStyles';

const TeamStatistics = ({teamStatistics, strings}) => {
  console.log('TeamStatistics.render()');
  return (
    <>
      <div style={styles.container}>
        <div style={{...tableStyles.headerView, ...styles.items}}>
          <div style={tableStyles.headerText}>{strings.medium.won}</div>
        </div>
        <div style={{...tableStyles.headerView, ...styles.items}}>
          <div style={tableStyles.headerText}>{strings.medium.drawn}</div>
        </div>
        <div style={{...tableStyles.headerView, ...styles.items}}>
          <div style={tableStyles.headerText}>{strings.medium.lost}</div>
        </div>
      </div>
      <div style={styles.container}>
        <div style={{...tableStyles.rowViewFirst, ...tableStyles.rowViewLast, ...styles.items, ...styles.row}}>
          <div style={styles.percentage}>{teamStatistics.wonPercentage}%</div>
          <div style={styles.quantity}>{teamStatistics.won}</div>
        </div>
        <div style={{...tableStyles.rowViewFirst, ...tableStyles.rowViewLast, ...styles.items, ...styles.row}}>
          <div style={styles.percentage}>{teamStatistics.drawnPercentage}%</div>
          <div style={styles.quantity}>{teamStatistics.drawn}</div>
        </div>
        <div style={{...tableStyles.rowViewFirst, ...tableStyles.rowViewLast, ...styles.items, ...styles.row}}>
          <div style={styles.percentage}>{teamStatistics.lostPercentage}%</div>
          <div style={styles.quantity}>{teamStatistics.lost}</div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  items: {
    flexDirection: 'column',
    flexBasis: 1,
    flexGrow: 1,
    justifyContent: 'center',
  },
  row: {
    height: 80,
  },
  percentage: {
    fontSize: 21,
    color: colors.textPrimary,
  },
  quantity: {
    color: colors.textSecondary,
  },
};

export default TeamStatistics;
