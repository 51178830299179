import React from 'react';
import apple from './../../images/app-store-badge.svg';
import google from './../../images/play-store-badge.png';

const StoreBadges = () => {
  console.log('StoreBadges.render()');
  return (
    <div style={styles.container} className="safe-area-desktop-small">
      <a style={styles.a} href="https://apps.apple.com/de/app/fu%C3%9Fball-ergebnisse-footy/id1602126180?pt=117271916&ct=fussball-ergebnis-de-footer&mt=8" target="_blank">
        <img src={apple} alt="Apple App Store Badge" style={styles.appStoreImage}/>
      </a>
      <a style={styles.a} href="http://play.google.com/store/apps/details?id=org.youfootball&utm_source=fussball-ergebnis-de-footer" target="_blank">
        <img src={google} alt="Google Play Store Badge" style={styles.playStoreImage}/>
      </a>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 8,
    marginRight: 8,
  },
  appStoreImage: {
    display: 'block',                                               // Remove space blow image
    height: 36,
    width: 'auto',
  },
  playStoreImage: {
    display: 'block',
    height: 53,
    width: 'auto',
  },
};

export default StoreBadges;
