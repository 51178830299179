/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {
  console.log('Footer.render()');
  return (
    <div id="footer" style={styles.container}>
      <a href="https://www.iubenda.com/nutzungsbedingungen/8253035" style={styles.link} target="_blank">Nutzungsbedingungen</a>
      <Link to="/impressum" style={styles.link}>Impressum</Link>
      <a href="https://www.iubenda.com/privacy-policy/54119735" style={styles.link} target="_blank">Datenschutz</a>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'stretch',
    minHeight: 44,
    height: 44,
    marginLeft: 16,
    marginRight: 16,
    borderTopWidth: 0.5,                                            /* iOS 13: Bars | Not accurate, it's a shadow  */
    borderTopStyle: 'solid',                                        /* iOS 13: Bars | Not accurate, it's a shadow  */
    borderTopColor: 'rgba(0, 0, 0, 0.1)',
    fontSize: 12,
    color: 'rgba(60, 60, 67, 0.6)',                                 /* iOS 13: Text secondary */
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
};

export default Footer;
