import React from 'react';

const Touch = ({onPress, style, children, iosComponent}) => {
  console.log('Touch.render()');
  return (
    <button style={style} onClick={onPress}>
      {children}
    </button>
  );
};

export default Touch;
