import React from 'react';
import tableStyles from './../../styles/tableStyles.js';

const DetailsBroadcasts = ({match, strings}) => {
  console.log('DetailsBroadcasts.render()');
  if (match.broadcasts === undefined) return null;
  const broadcasts = match.broadcasts?.filter(({region}) => region === 'de');
  if (broadcasts.length === 0) return null;                         // No regional broadcasts found
  return (
    <>
      <div style={tableStyles.headerView}>
        <div style={tableStyles.headerText}>{strings.medium.televisionChannel}</div>
      </div>
      {broadcasts.map((broadcast, index, tvs) => {
        const first = index === 0;
        const last = index === (tvs?.length - 1);
        return (
          <div style={first && last ? tableStyles.rowViewFirstLast : first ? tableStyles.rowViewFirst : last ? tableStyles.rowViewLast : tableStyles.rowView} key={broadcast.stringId}>
            <div /*numberOfLines={1}*/>{strings.medium[broadcast.stringId]}</div>
          </div>
        );
      })}
      <div style={tableStyles.footerViewEmpty}/>
    </>
  );
};

export default DetailsBroadcasts;
