import React from 'react';
import Lazy from './../Lazy.js';
import Title from './../Title.js';
import NotFound from './../NotFound.js';
import TeamStatistics from './TeamStatistics';
import TeamNextMatches from './TeamNextMatches';
import TeamPastMatches from './TeamPastMatches';
import withErrorBoundary from './../common/withErrorBoundary.js';
import getTeamStatistics from './../../helper/getTeamStatistics';
import tableStyles from './../../styles/tableStyles.js';

const Team = ({matches, strings, teamId}) => {
  console.log('Team.render()');

  if (strings.medium.teams[teamId] === undefined) return <NotFound/>;

  const teamStatistics = getTeamStatistics(teamId, matches);

  /* Head Title */
  document.title = `${strings.medium.teams[teamId]} - Spielplan, Ergebnisse, TV`;

  /* Head Meta Description */
  document.head.children.description.content = `Alle Spiele, Fußball Ergebnisse, TV Sender Übertragung, Infos, nächste Spiele und Spielplan von ${strings.small.teams[teamId]}`;

  return (
    <div id="team" style={tableStyles.container} className="safe-area-desktop-small">
      <Lazy>
        <Title>{strings.medium.teams[teamId]}</Title>
        <TeamStatistics teamStatistics={teamStatistics} strings={strings}/>
        <div style={tableStyles.footerViewEmpty}/>
        <TeamPastMatches teamId={teamId} teamStatistics={teamStatistics} strings={strings}/>
        <TeamNextMatches teamId={teamId} teamStatistics={teamStatistics} strings={strings}/>
      </Lazy>
    </div>
  );
};

export default withErrorBoundary(Team, 'Team');
