/**
 * Get the first current match of a tournament
 */

import searchTournamentStart from './searchTournamentStart.js';
import searchTournamentEnd from './searchTournamentEnd.js';

const searchTournamentCurrentStart = (matches, tournamentId) => { try {
  const tournamentStart = searchTournamentStart(matches, tournamentId);
  const tournamentEnd = searchTournamentEnd(matches, tournamentId);
  for (let i = tournamentStart; i <= tournamentEnd; i++) {
    const currentMatch = matches[i];
    if (currentMatch.current === true)
      return i;                                                     // Found
  }
  return null;                                                      // No current=true in tournament
} catch (e) { return null; }};


export default searchTournamentCurrentStart;
