/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {BrowserRouter, Routes, Route, useLocation, Navigate} from 'react-router-dom';
import NavBar from './../navBar/NavBar.js';
import TabBar from './../tabBar/TabBar.js';
import StoreBadges from './StoreBadges.js';
import Footer from './../footer/Footer.js';
import Index from './../Index.js';
import Calendar from './../calendar/Calendar.js';
import Details from './../details/Details.js';
import Matches from './../match/Matches.js';
import More from './../more/More.js';
import Live from './../Live.js';
import TournamentCalendar from './../TournamentCalendar.js';
import SiteNotice from './../SiteNotice.js';
import Team from './../team/Team.js';
import NotFound from './../NotFound.js';
import useInterval from './../../helper/useInterval.js';
import tournamentPaths from './../../helper/tournamentPaths.js';
import matchesData from './../../data/m-i33op67qq0.json';
import standingsData from './../../data/s-ppdhu488qb.json';
import stringsData from './../../data/str-zt7vyfloiq-de.json';

const App = () => {
  console.log('App.render()');

  const [matches, setMatches] = React.useState(extract(matchesData.d));
  const [standings, setStandings] = React.useState(extract(standingsData.d));
  const [strings, setStrings] = React.useState(extract(stringsData.d));

  const fetchData = async () => { try {
    let [matchesTmp, standingsTmp] = await Promise.all([
      fetch('https://www.fussball-ergebnis.de/prod/v1/m-i33op67qq0.json.gz'),
      fetch('https://www.fussball-ergebnis.de/prod/v1/s-ppdhu488qb.json.gz'),
    ]);
    matchesTmp = extract((await matchesTmp.json()).d);
    standingsTmp = extract((await standingsTmp.json()).d);
    matchesChanged(matches, matchesTmp) && setMatches(matchesTmp);
    standingsChanged(standings, standingsTmp) && setStandings(standingsTmp);
  } catch (e) { console.log(e); }};

  useInterval(() => fetchData(), 20000);
  React.useEffect(() => {
    fetchData();
    const p = async () => { try {
      let stringsTmp = await fetch('https://www.fussball-ergebnis.de/prod/v1/str-zt7vyfloiq-de.json.gz');
      stringsTmp = extract((await stringsTmp.json()).d);
      if (JSON.stringify(strings) !== JSON.stringify(stringsTmp)) {
        setStrings(stringsTmp);
      }
    } catch (e) { console.log(e); }}
    p();
  }, []);

  return (
    <BrowserRouter>
      <div id="app" style={styles.container}>
        <NavBar/>
        <TabBar/>
        <main style={styles.main}>
          <Routes>
            <Route path="/" element={<Index matches={matches} strings={strings}/>}/>

            {
              Object.keys(tournamentPaths).flatMap(tournamentId => {
                tournamentId = parseInt(tournamentId);
                return [
                  <Route path={`${tournamentPaths[tournamentId]}`} element={<Matches tournamentId={parseInt(tournamentId)} matches={matches} standings={standings} strings={strings}/>}/>,
                  <Route path={`${tournamentPaths[tournamentId]}/heute`} element={<TournamentCalendar day="today" tournamentId={tournamentId} matches={matches} standings={standings} strings={strings}/>}/>,
                  <Route path={`${tournamentPaths[tournamentId]}/gestern`} element={<TournamentCalendar day="yesterday" tournamentId={tournamentId} matches={matches} standings={standings} strings={strings}/>}/>,
                  <Route path={`${tournamentPaths[tournamentId]}/morgen`} element={<TournamentCalendar day="tomorrow" tournamentId={tournamentId} matches={matches} standings={standings} strings={strings}/>}/>,
                ];
              })
            }

            <Route path="/heute" element={<Calendar matches={matches} strings={strings}/>}/>
            <Route path="/gestern" element={<Calendar matches={matches} strings={strings}/>}/>
            <Route path="/morgen" element={<Calendar matches={matches} strings={strings}/>}/>
            <Route path="/samstag" element={<Calendar matches={matches} strings={strings}/>}/>
            <Route path="/sonntag" element={<Calendar matches={matches} strings={strings}/>}/>

            <Route path="/live" element={<Live matches={matches} strings={strings}/>}/>
            <Route path="/alle-ligen" element={<More strings={strings}/>}/>
            <Route path="/impressum" element={<SiteNotice/>}/>

            {/* Deprecated Routes */}
            <Route path="/1-bundesliga" element={<Navigate to="/bundesliga"/>}/>

            <Route path="*" element={<Validator matches={matches} strings={strings}/>}/>
          </Routes>
        </main>
        <StoreBadges/>
        <Footer/>
      </div>
    </BrowserRouter>
  );
};

const Validator = ({matches, strings}) => {
  const path = useLocation().pathname;

  const details = path.match('^/[0-9a-z-]+-gegen-[0-9a-z-]+-([0-9]+)$');
  if (details) return <Details matches={matches} strings={strings} matchId={parseInt(details[1])}/>

  const calendar = path.match('^/([0-9]{1,2})-([0-9]{1,2})-([0-9]{4})$')
  if (calendar) {
    const date = new Date(parseInt(calendar[3]), parseInt(calendar[2]-1), parseInt(calendar[1]));
    return <Calendar matches={matches} strings={strings} date={date}/>
  }

  const team = path.match('^/[0-9a-z-]+/([0-9]+)$');
  if (team) return <Team matches={matches} strings={strings} teamId={parseInt(team[1])}/>

  return <NotFound/>
}

/*
  This function got obfuscated to _0xa580.
  Origin code:
    const extract = (m) => {
      let tmp;
      tmp = m.slice(0, 1) + m.slice(2, 6) + m.slice(7);
      tmp = atob(tmp);
      tmp = decodeURIComponent(escape(tmp));
      return JSON.parse(tmp);
    };
*/
// eslint-disable-next-line
function _0xa580(){const _0x1dee91=['475671fDzPxQ','slice','16UWOyHB','2245nhxPAo','48339tEPNWF','468354ozBccV','28857mVpXgM','161588PhdRhC','1148IXLmqb','391104tjbKPq'];_0xa580=function(){return _0x1dee91;};return _0xa580();}(function(_0x5d01e4,_0x1fc3f4){const _0x4c7a85=_0x4014,_0x54bde8=_0x5d01e4();while(!![]){try{const _0x222b77=parseInt(_0x4c7a85(0xdf))/0x1+-parseInt(_0x4c7a85(0xe1))/0x2+parseInt(_0x4c7a85(0xde))/0x3+-parseInt(_0x4c7a85(0xe0))/0x4*(-parseInt(_0x4c7a85(0xe5))/0x5)+-parseInt(_0x4c7a85(0xdd))/0x6+parseInt(_0x4c7a85(0xe2))/0x7+parseInt(_0x4c7a85(0xe4))/0x8*(parseInt(_0x4c7a85(0xdc))/0x9);if(_0x222b77===_0x1fc3f4)break;else _0x54bde8['push'](_0x54bde8['shift']());}catch(_0x83b480){_0x54bde8['push'](_0x54bde8['shift']());}}}(_0xa580,0x19ac2));function _0x4014(_0x22f7d0,_0x2e2ad8){const _0xa580e0=_0xa580();return _0x4014=function(_0x401450,_0x4cd1b1){_0x401450=_0x401450-0xdc;let _0x426ab6=_0xa580e0[_0x401450];return _0x426ab6;},_0x4014(_0x22f7d0,_0x2e2ad8);}const extract=_0x2945e9=>{const _0x1d1a21=_0x4014;let _0x237a73;return _0x237a73=_0x2945e9[_0x1d1a21(0xe3)](0x0,0x1)+_0x2945e9[_0x1d1a21(0xe3)](0x2,0x6)+_0x2945e9[_0x1d1a21(0xe3)](0x7),_0x237a73=atob(_0x237a73),_0x237a73=decodeURIComponent(escape(_0x237a73)),JSON['parse'](_0x237a73);};

const matchesChanged = (oldMatches, newMatches) => {
  if (newMatches.length !== oldMatches.length) return true;
  for (let i = 0; i < newMatches.length; i++) {
    if (newMatches[i].matchId !== oldMatches[i].matchId || newMatches[i].changedAt !== oldMatches[i].changedAt)
      return true;
  }
  return false;
};

const standingsChanged = (oldStandings, newStandings) => {
  if (newStandings.length !== oldStandings.length) return true;
  for (let i = 0; i < newStandings.length; i++) {
    if (newStandings[i].hash !== oldStandings[i].hash) return true;
  }
  return false;
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',                                                 /* Sticky footer */
  },
  main: {
    flexGrow: 1,                                                    /* Sticky footer */
  }
}

export default App;
