/**
 * I couldn't find a solution with <div> as container. Use View.
 */

import React from 'react';
import isMatchEnded from './../../helper/isMatchEnded.js';
import colors from './../../colors.js';
import MatchResultIcon from './MatchResultIcon.js';

const MatchRight = ({match, strings, isTV = true, detailsTeamId}) => {
  console.log('MatchRight.render()');
  if (detailsTeamId && isMatchEnded(match.status)) return (
    <div style={styles.container}>
      <MatchResultIcon match={match} teamId={detailsTeamId} strings={strings}/>
    </div>
  );
  const broadcasts = match.broadcasts?.filter(({region}) => region === 'de');
  const broadcast1StringId = broadcasts?.[0]?.stringId;
  const broadcast2StringId = broadcasts?.[1]?.stringId;
  return (
    <div style={styles.container}>
      {isTV && broadcast1StringId ? <div /*numberOfLines={1}*/ /*maxFontSizeMultiplier={1.3}*/ style={isMatchEnded(match.status) ? styles.textEnded : styles.text}>{strings.small[broadcast1StringId] || strings.shortcut[broadcast1StringId] || broadcast1StringId}</div> : null}
      {isTV && broadcast2StringId ? <div /*numberOfLines={1}*/ /*maxFontSizeMultiplier={1.3}*/ style={isMatchEnded(match.status) ? styles.textEnded : styles.text}>{strings.small[broadcast2StringId] || strings.shortcut[broadcast2StringId] || broadcast2StringId}</div> : null}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 50,
    flexShrink: 0,
    flexGrow: 0.4,
    alignItems: 'flex-end',
  },
  text: {
    fontSize: 11,
    color: colors.textPrimary,
    display: 'initial',                                               // It's not allowed to use flex
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textEnded: {
    fontSize: 11,
    color: colors.textSecondary,
    display: 'initial',                                               // It's not allowed to use flex
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default MatchRight;
