/**
 * Performance hints
 * - With 2k iterations, new Date(), map, for, push ist very fast (<1ms)
 * - push(<div/>) is very slow (120ms)
 * - filter+map is 20% slower than for (19ms/15ms) but still very fast on 20k iterations
 */

import React from 'react';
import {Link} from 'react-router-dom';
import Title from './Title.js';
import Lazy from './Lazy.js';
import Icon from './Icon.js';
import NextMatchesCountdown from './common/NextMatchesCountdown.js';
import withErrorBoundary from './common/withErrorBoundary.js';
import isMatchActive from './../helper/isMatchActive.js';
import tableStyles from './../styles/tableStyles.js';
import colors from './../colors.js';

const Index = ({matches, strings, userDate}) => {
  console.log('Index.render()');

  /* Meta */
  document.title = 'Fussball-Ergebnis.de - Alle Spiele, alle TV Übertragungen';
  document.head.children.description.content = 'Fussball-Ergebnis.de bietet dir alle Spiele, Ergebnisse und Tore live inkl. TV Sender zur Bundesliga, Champions League, EM, WM und vielen weiteren Ligen.';

  // Get live matches
  const liveMatchesSum = matches.filter((match) => isMatchActive(match.status)).length;

  return (
    <div id="index" style={tableStyles.container} className="safe-area-desktop-small">
      <Lazy>
        <Title>Übersicht</Title>



        {/* Live */}
        <div style={tableStyles.headerView}>
          <div style={tableStyles.headerText}>Laufende Spiele 🔴</div>
        </div>
        <Link to="/live" style={{...tableStyles.rowViewFirstLast, ...styles.liveMatchesSumText}}>
          {liveMatchesSum}
          <span style={styles.liveText}>LIVE</span>
          <Icon androidName="chevron-right" iosName="chevron-forward-outline" size={33} color={colors.primary} style={styles.chevron}/>
        </Link>
        <div style={tableStyles.footerViewEmpty}/>



        {/* Next */}
        <NextMatchesCountdown matches={matches}/>



        {/* Calendar */}
        <div style={tableStyles.headerView}>
          <div style={tableStyles.headerText}>Kalender</div>
        </div>
        <div style={{...tableStyles.rowViewFirst, ...styles.autoHeight}}>
          <div style={{...tableStyles.rowViewText, ...styles.rowViewText}}>Alle Spiele chronologisch in einem Kalender:</div>
        </div>
        <div style={{...tableStyles.rowViewLast, ...styles.dayContainer}}>
          <Link to="/gestern" style={{...tableStyles.rowViewText, ...styles.dayButton}}>Gestern</Link>
          <Link to="/heute" style={{...tableStyles.rowViewText, ...styles.dayButton}}>Heute</Link>
          <Link to="/morgen" style={{...tableStyles.rowViewText, ...styles.dayButton}}>Morgen</Link>
        </div>
        <div style={tableStyles.footerViewEmpty}/>



        {/* Leagues */}
        <div style={tableStyles.headerView}>
          <div style={tableStyles.headerText}>Ligen</div>
        </div>
        <div style={{...tableStyles.rowViewFirst, ...styles.autoHeight}}>
          <div style={{...tableStyles.rowViewText, ...styles.rowViewText}}>Die Bundesliga, Champions League, DFB Pokal, WM, EM und vieles mehr findest du hier:</div>
        </div>
        <div style={{...tableStyles.rowViewLast, ...styles.dayContainer}}>
          <Link to="/alle-ligen" style={{...tableStyles.rowViewText, ...styles.dayButton}}>Alle Ligen</Link>
        </div>


        
        <div style={tableStyles.footerViewEmpty}/>
      </Lazy>
    </div>
  );
};

const styles = {
  rowViewText: {
    flexGrow: 1,
    textAlign: 'center',
  },
  liveMatchesSumText: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
    fontWeight: 'bold',
  },
  liveText: {
    marginLeft: 8,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: 'red',
    color: 'white',
  },
  chevron: {
    position: 'absolute',
    right: -2,
  },
  autoHeight: {
    height: 'auto',
    minHeight: 34,
  },
  dayContainer: {
    justifyContent: 'space-around',
  },
  dayButton: {
    color: colors.primary,
    fontWeight: 'bold',
  },
};

export default withErrorBoundary(Index, 'Index');
