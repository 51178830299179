const colors = {
  backgroundColor: '#ffffff',             //Facebook / Google text background
  backgroundColorSecondary: '#fafafa',    //Material
  backgroundColorTertiary: '#f5f5f5',
  backgroundColorQuaternary: '#e0e0e0',
  textPrimary: '#000000de',               //Material. Alpha: 87%
  textSecondary: 'rgba(60, 60, 67, 0.60)',                          // iOS 15 | Secondary label, table header, table footer, subtitle, search placeholder, row text right
  textTertiary: 'rgba(60, 60, 67, 0.30)',                           // iOS 15 | Tertiary label, list icons, placeholder
  textDisabled: '#00000061',              //Material. Alpha: 38% //and for hints
  textPrimaryDark: '#ffffff',           //Material. Alpha: 100%
  textSecondaryDark: '#ffffffb3',         //Material. Alpha: 70%
  textDisabledDark: '#ffffff80',          //Material. Alpha: 50%
  divider: '#0000001f',                   //Material. Alpha: 12%
  primaryLighter: '#ef5350',              //Material. Red. 400
  primary: '#f44336',                     // Cup = EU Flag, Footy = Material Red 500
  primaryDarker: '#e53935',               //Material. Red. 600
  rippleDefault: '#0000001f',             //Material. Source: https://stackoverflow.com/a/52253682

  secondary: '#607d8b',                   //Material. Blue Grey. 500
  secondary700: '#455a64',                //700
  secondary400: '#78909c',                //400
  secondary300: '#90a4ae',                //300
  secondary200: '#b0bec5',                //200
  secondaryA87: '#607d8bde',              //Alpha: 87%
  secondaryA54: '#607d8b8a',              //Alpha: 54%
  secondaryA38: '#607d8b61',              //Alpha: 38%
  secondaryA30: '#607d8b4d',              //Alpha: 30%
  secondaryA12: '#607d8b1f',              //Alpha: 12%

  secondaryX87: '#758e9a',                //#607d8bde: alpha removed
  secondaryX54: '#a9b9c0',                //#607d8b8a: alpha removed
  secondaryX38: '#c2ced3',                //#607d8b61: alpha removed
  secondaryX30: '#cfd8dc',                //#607d8b4d: alpha removed
  secondaryX30Finished: '#b8d1c4',        //#cfd8dc + #4caf502c OVERLAY, it has to be above
  secondaryX12: '#eceff1',                //#607d8b1f: alpha removed


  matchFinished: '#e0f1e1',               //Material. Green. 100. Alpha: 17% = #4caf502c => removed alpha = #e0f1e1
  matchFinishedOld: '#4caf502c',          //Material. Green. 100. Alpha: 17% (Just to determine colors)
  matchActive: '#fcc6c2',                 //Material. Red. 500. Alpha: 38% = #f443364d => removed alpha = #fcc6c2
  matchActiveOld: '#f443364d',            //Material. Red. 500. Alpha: 38% (Just to determine colors)

  green: '#8bc34a',                       //Material. Green. 500
  greenA54: '#8bc34a8a',                  //Alpha: 54%
  greenA38: '#8bc34a4d',                  //Alpha: 38%

  blue600: '#1E88E5',                     //Material. Blue. 600
  blue: '#2196f3',                        //Material. Blue. 500
  blueA54: '#2196f38a',                   //Alpha: 54%
  blueA38: '#2196f34d',                   //Alpha: 38%

  red: '#f44336',                         //Material. Red. 500
  redA54: '#f443368a',                    //Material. Red. 500. Alpha: 54%
  redA38: '#f443364d',                    //Material. Red. 500. Alpha: 38%

  win1: '#8bc34a8a',                        //Material. Green. 500

  iosTouchableHighlightUnderlayColor: 'rgb(229, 229, 233)',         // iOS 15 | Measured with Digital Colours Meter in settings. Warning: Deeper in menu there is 209, 209, 214
  line: 'rgb(198, 197, 199)',                                       // iOS 15 | table row, table round row, table group top, table group bottom | sometimes it even differs a little | Use with StyleSheet.hairlineWidth

};

export default colors;
