import React from 'react';
import isMatchActive from './../../helper/isMatchActive.js';
import colors from './../../colors.js';

const MatchGoals = ({match}) => {
  console.log('MatchGoals.render()');
  const isHighScore = (match.homeGoals > 9) || (match.awayGoals > 9);
  return (
    <div style={isMatchActive(match.status) ? {...styles.container, ...styles.active} : styles.container}>
      <div style={isHighScore ? {...styles.homeGoals, ...styles.highScore} : styles.homeGoals} /*maxFontSizeMultiplier={isHighScore ? 1.2 : 1.6}*/>{match.homeGoals === undefined ? '-' : match.homeGoals}</div>
      <div style={isHighScore ? styles.separatorHighScore : styles.separator} /*maxFontSizeMultiplier={1.6}*/>:</div>
      <div style={isHighScore ? {...styles.awayGoals, ...styles.highScore} : styles.awayGoals} /*maxFontSizeMultiplier={isHighScore ? 1.2 : 1.6}*/>{match.awayGoals === undefined ? '-' : match.awayGoals}</div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexBasis: 38,
    flexShrink: 0,
    flexGrow: 0.45,
    flexDirection: 'row',
    alignItems: 'stretch',
    height: 24,
  },
  active: {
    backgroundColor: colors.matchActive,
  },

  homeGoals: {
    flexBasis: 13,
    flexShrink: 1,
    flexGrow: 1,
    alignSelf: 'center',
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
  awayGoals: {
    flexBasis: 13,
    flexShrink: 1,
    flexGrow: 1,
    alignSelf: 'center',
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.textPrimary,
  },
  highScore: {
    fontSize: 11,
  },

  separator: {
    flexBasis: 10,
    flexShrink: 1,
    flexGrow: 0.2,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 14,
    color: colors.textPrimary,
  },
  separatorHighScore: {
    flexShrink: 1,
    flexGrow: 0.2,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 10,
    color: colors.textPrimary,
  },
};

export default MatchGoals;
