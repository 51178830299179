/**
 * Get the last match of a tournament
 */

const searchTournamentEnd = (matches, tournamentId) => { try {
  return treeSearch(matches, tournamentId, 0, matches.length-1, 0);
} catch (e) { return null; }};

const treeSearch = (matches, tournamentId, lower, upper, iterations) => {
  iterations++;                                                     // Prevent unknown endless loops
  if (iterations === 30) return null;
  if (lower > upper) return null;                                   // Not found

  const median = lower + Math.round((upper - lower) / 2);
  const currentMatch = matches[median];
  const nextMatch = matches[median+1];

  if (tournamentId === currentMatch.tournamentId && tournamentId !== nextMatch?.tournamentId) // Found
    return median;
  else if (tournamentId < currentMatch.tournamentId)                // Not found: Go left. "<" is important, do not use "<=" like in searchTournamentStart
    return treeSearch(matches, tournamentId, lower, median-1, iterations);
  else                                                              // Not found: Go right.
    return treeSearch(matches, tournamentId, median+1, upper, iterations);
};

export default searchTournamentEnd;
