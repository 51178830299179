/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import DetailsComparisonLastMatches from './DetailsComparisonLastMatches.js';
import tableStyles from './../../styles/tableStyles.js';
import getTeamStatistics from './../../helper/getTeamStatistics.js';

const DetailsComparison = ({match, matches, strings}) => {
  console.log('DetailsComparison.render()');
  const homeTeamStatistics = React.useMemo(() => getTeamStatistics(match.homeTeamId, matches), [match.homeTeamId]);
  const awayTeamStatistics = React.useMemo(() => getTeamStatistics(match.awayTeamId, matches), [match.awayTeamId]);
  return (
    <>
      <div style={tableStyles.headerView}>
        <div style={tableStyles.headerText}>{strings.medium.comparison}</div>
      </div>
      <div style={tableStyles.rowViewFirst}>
        <DetailsComparisonLastMatches matches={homeTeamStatistics.pastMatches} teamId={match.homeTeamId} strings={strings}/>
        <div style={styles.comparisonKey}>{strings.medium.last7matches}</div>
        <DetailsComparisonLastMatches matches={awayTeamStatistics.pastMatches} teamId={match.awayTeamId} strings={strings}/>
      </div>
      <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>
      <div style={tableStyles.rowViewLast}>
        <div style={styles.comparisonValue}>{homeTeamStatistics.wonPercentage} %</div>
        <div style={styles.comparisonKey}>{strings.medium.won}</div>
        <div style={styles.comparisonValue}>{awayTeamStatistics.wonPercentage} %</div>
      </div>
      <div style={tableStyles.footerViewEmpty}/>
    </>
  );
};

const styles = {
  comparisonKey: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  comparisonValue: {
    flexBasis: 1,
    flexGrow: 1,
    textAlign: 'center',
  },
};

export default DetailsComparison;
