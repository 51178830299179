import React from 'react';
import DetailsEvent from './DetailsEvent.js';
import tableStyles from './../../styles/tableStyles.js';

const DetailsEvents = ({match, strings}) => {
  console.log('DetailsEvents.render()');
  if (!match?.events?.length) return null;                          // If the property is undefined or the array is empty (length=0) return null
  return (
    <>
      <div style={tableStyles.headerView}>
        <div style={tableStyles.headerText}>{strings.medium.events}</div>
      </div>
      {match.events.map((event, index, events) => (
        <DetailsEvent
          event={event}
          first={index === 0}
          last={index === (events.length - 1)}
          key={event.type + event.minute + event.player}
          strings={strings}
        />
      ))}
      <div style={tableStyles.footerViewEmpty}/>
    </>
  );
};

export default DetailsEvents;
