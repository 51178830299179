import React from 'react';
import colors from './../../colors.js';

const MatchTeam = ({match, strings, team}) => {
  console.log('MatchTeam.render()');

  const teamName = team === 'home'
    ? strings.small.teams[match.homeTeamId] || strings.medium.teams[match.homeTeamId] || strings.small[match.homeTeamStringId] || strings.medium[match.homeTeamStringId] || match.homeTeamStringId || match.homeTeamId
    : strings.small.teams[match.awayTeamId] || strings.medium.teams[match.awayTeamId] || strings.small[match.awayTeamStringId] || strings.medium[match.awayTeamStringId] || match.awayTeamStringId || match.awayTeamId;

  const style = team === 'home'
    ? styles.homeText
    : styles.awayText;

  return (
    <div style={style} /*numberOfLines={1}*/>
      {teamName}
      {match?.group !== undefined && team === 'away' && <div style={styles.groupText}> ({match?.group})</div>}
    </div>
  );
};

const styles = {
  homeText: {
    flexBasis: 111,
    flexShrink: 1,
    flexGrow: 1,
    paddingRight: 5,
    textAlign: 'right',
    fontSize: 14,
    color: colors.textPrimary,
    display: 'initial',                                             // It's not allowed to use flex
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  awayText: {
    flexBasis: 111,
    flexShrink: 1,
    flexGrow: 1,
    paddingLeft: 5,
    textAlign: 'left',
    fontSize: 14,
    color: colors.textPrimary,
    display: 'initial',                                             // It's not allowed to use flex
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  groupText: {
    display: 'inline',
    fontSize: 11,
  },
};

export default MatchTeam;
