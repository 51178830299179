/**
 * offset = 0: same as isToday()
 * offset = 1: same as isTomorrow()
 * offset = -1: same as isYesterday() 
 * offset = -2: same as day before yesterday
 */

const isDay = (date, offset, today = new Date()) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() + offset);
  return (
    yesterday.getFullYear() === date.getFullYear() &&
    yesterday.getMonth() === date.getMonth() &&
    yesterday.getDate() === date.getDate()
  );
};

export default isDay;
