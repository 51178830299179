/**
 * new Date() is extremly fast: 2000x = <1ms (DEV)
 * You can pass in your own new Date as second param
 * to potentially speed it up and maybe reduce memory (not measured)
 * but even with 10k iterations its barely measurable (few ms, dev)
 */

const isToday = (date, today = new Date()) => {
  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
};

export default isToday;
