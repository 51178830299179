/**
 * There are two styles:
 * 1. Today 15:30
 * 2. Sa. 12.02.2022 15:30
 */

import isToday from './../../helper/isToday.js';
import isTomorrow from './../../helper/isTomorrow.js';
import isYesterday from './../../helper/isYesterday.js';
import getWeekdayStringId from './../../helper/getWeekdayStringId.js';

const getDate = (date, strings) => {
  let leftString;
  if      (isToday(date)) leftString = '🔥 ' + strings.medium.today;
  else if (isTomorrow(date)) leftString = strings.medium.tomorrow;
  else if (isYesterday(date)) leftString = strings.medium.yesterday;
  else {
    let weekdayString = strings.medium[getWeekdayStringId(date)].substring(0, 2);
    let dateString = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    leftString = weekdayString + '. ' + dateString;
  }
  const timeString = (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  return leftString + ' ' + timeString;
};

export default getDate;
