/**
 * Based on iOS 15 table with rounded edges, optimized for football
 * The backgroundColor has to be 'rgb(242, 242, 247)' (iOS 15): use tableStyles.container
 */

let styles = {
  container: {
    display: 'flex',                                                // Web
    flexDirection: 'column',                                        // Web
    flexGrow: 1,
    backgroundColor: 'rgb(242, 242, 247)',                          // iOS 15 | table round
  },
  headerView: {
    display: 'flex',                                                // Web
    flexDirection: 'row',
    alignItems: 'center',
    height: 33,                                                     // iOS 15 | table round header
    paddingLeft: 16,                                                // iOS 15 | table round header | default 32px
    paddingRight: 16,                                               // iOS 15 | table round header | default 32px
  },
  headerViewEmpty: {
    height: 14,                                                     // iOS 15 | table round header
  },
  headerText: {
    fontSize: 13,                                                   // iOS 15 | table round header
    color: 'rgba(60, 60, 67, 0.6)',                                 // iOS 15 | table round header | warning 0.6 transparency, the background should be rgb(242, 242, 247)
  },
  sectionView: {
    display: 'flex',                                                // Web
    flexDirection: 'row',
    alignItems: 'center',
    height: 28,                                                     // iOS 15 | table section
    marginLeft: 8,                                                  // Hint: There is no table round section in iOS 15, therefore there is no margin
    marginRight: 8,                                                 // Hint: There is no table round section in iOS 15, therefore there is no margin
    paddingLeft: 8,                                                 // iOS 15 | table section | default 16px
    paddingRight: 8,                                                // iOS 15 | table section | default 16px
    backgroundColor: 'rgb(233, 233, 235)',                          // iOS 15 | table section | default rgba(120, 120, 128, 0.16) | converted to RGB, because the background could influence the color
  },
  sectionViewFirst: {
    display: 'flex',                                                // Web
    flexDirection: 'row',
    alignItems: 'center',
    height: 28,                                                     // iOS 15 | table section
    marginLeft: 8,                                                  // Hint: There is no table round section in iOS 15, therefore there is no margin
    marginRight: 8,                                                 // Hint: There is no table round section in iOS 15, therefore there is no margin
    paddingLeft: 8,                                                 // iOS 15 | table section | default 16px
    paddingRight: 8,                                                // iOS 15 | table section | default 16px
    backgroundColor: 'rgb(233, 233, 235)',                          // iOS 15 | table section | default rgba(120, 120, 128, 0.16) | converted to RGB, because the background could influence the color
    borderTopLeftRadius: 10,                                        // iOS 15 | table round row
    borderTopRightRadius: 10,                                       // iOS 15 | table round row
  },
  sectionText: {
    fontSize: 15,                                                   // iOS 15 | table section | default 17px
    fontWeight: '500',                                              // iOS 15 | table section | default semibold | Google: normal/regular: 400, medium: 500, semibold: 600, bold: 700 | fontWeight is currently broken on Android, but it will be fixed: https://github.com/facebook/react-native/issues/26193 | Real React Native iOS test: normal: 400, semibold: 500, bold: 600.
    color: 'black',                                                 // iOS 15 | table section | Set colors explicit. This prevents a RN bug where text disappears if the color state changes. E. g. from red to default. Better: from red to black.
  },
  rowView: {
    position: 'relative',                                           // Web | required to position absolute child elements
    display: 'flex',                                                // Web
    flexDirection: 'row',
    alignItems: 'center',
    height: 34,                                                     // iOS 15 | table round row | default 44px
    marginLeft: 8,                                                  // iOS 15 | table round row | default 16px
    marginRight: 8,                                                 // iOS 15 | table round row | default 16px
    paddingLeft: 8,                                                 // iOS 15 | table section | default 16px
    paddingRight: 8,                                                // iOS 15 | table section | default 16px
    backgroundColor: 'white',                                       // iOS 15 | table round row
  },
  rowText: {
    fontSize: 14,                                                   // iOS 15 | table round row | default 17,
    color: 'black',                                                 // iOS 15 | table round row | Set colors explicit. This prevents a RN bug where text disappears if the color state changes. E. g. from red to default. Better: from red to black.
  },
  lineViewContainer: {
    marginLeft: 8,                                                  // iOS 15 | table round row | default 16px
    marginRight: 8,                                                 // iOS 15 | table round row | default 16px
    backgroundColor: 'white',                                       // iOS 15 | table round row
  },
  lineView: {
    borderTopStyle: 'solid',                                        // Web
    borderTopWidth: 0.5,                                            // Web
    borderTopColor: 'rgb(198, 197, 199)',                           // iOS 15 | table round row
    marginLeft: 8,                                                  // iOS 15 | table round row | default 16px
    marginRight: 8,                                                 // iOS 15 | table round row | default 0px
  },
  footerView: {
    display: 'flex',                                                // Web
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,                                                     // iOS 15 | table round footer
    paddingLeft: 16,                                                // iOS 15 | table round header | default 32px
    paddingRight: 16,                                               // iOS 15 | table round header | default 32px
  },
  footerViewEmpty: {
    height: 25,                                                     // iOS 15 | table round footer
  },
  footerText: {
    fontSize: 13,                                                   // iOS 15 | table round footer
    color: 'rgba(60, 60, 67, 0.6)',                                 // iOS 15 | table round footer | warning 0.6 transparency, the background should be rgb(242, 242, 247)
  },
};

styles = {
  ...styles,
  rowViewFirst: {
    ...styles.rowView,
    borderTopLeftRadius: 10,                                        // iOS 15 | table round row
    borderTopRightRadius: 10,                                       // iOS 15 | table round row
  },
  rowViewLast: {
    ...styles.rowView,
    borderBottomLeftRadius: 10,                                     // iOS 15 | table round row
    borderBottomRightRadius: 10,                                    // iOS 15 | table round row
  },
};

styles = {
  ...styles,
  rowViewFirstLast: {
    ...styles.rowViewFirst,
    ...styles.rowViewLast,
  },
};

export default styles;
