import React from 'react';
import Icon from './../Icon.js';
import Touch from './../Touch.js';
import tableStyles from './../../styles/tableStyles.js';
// import TVSwitch from '../settings/TVSwitch.js';
import colors from './../../colors.js';

const MatchFooter = ({strings, lastCurrentMatch, tournamentWillChange, isTV, dispatch, onPress}) => {
  console.log('MatchFooter.render()');
  if (!lastCurrentMatch) return <div style={tableStyles.footerViewEmpty}/>;
  return (
    <div style={tableStyles.footerView}>
      <div style={styles.placeholder}/>
      {
        !tournamentWillChange && (
          <Touch style={styles.touch} onPress={onPress} iosComponent="TouchableOpacity">
            <Icon androidName="chevron-down" iosName="chevron-down-outline" androidSize={33} iosSize={30} color={colors.primary} style={styles.icon}/>
          </Touch>
        )
      }
      <div style={styles.placeholder}>
        {/* <TVSwitch isTV={isTV} strings={strings} dispatch={dispatch}/> */}
      </div>
    </div>
  );
};

const styles = {
  touch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 80,
  },
  placeholder: {
    flexBasis: 1,
    flexGrow: 1,
  },
};

export default MatchFooter;
