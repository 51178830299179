import React from 'react';
import DetailsHeader from './DetailsHeader.js';
import DetailsEvents from './DetailsEvents.js';
import DetailsComparison from './DetailsComparison.js';
import DetailsBroadcasts from './DetailsBroadcasts.js';
import Lazy from './../Lazy.js';
import withErrorBoundary from './../common/withErrorBoundary.js';
import getRoundName from './../../helper/getRoundName.js';
import isTomorrow from './../../helper/isTomorrow.js';
import isToday from './../../helper/isToday.js';
import isYesterday from './../../helper/isYesterday.js';
import isMatchEnded from './../../helper/isMatchEnded.js';
import isActive from './../../helper/isMatchActive.js';
import tableStyles from './../../styles/tableStyles.js';

const Details = ({matches, strings, matchId}) => {
  console.log('Details.render()');

  let match;
  for (const m of matches) {
    if (m.matchId === matchId) {
      match = m;
      break;
    }
  }
  if (match === undefined) return null;                             // Match not found

  /* Meta */
  const homeTeam = strings.medium.teams[match.homeTeamId];
  const awayTeam = strings.medium.teams[match.awayTeamId];
  const homeTeamSmall = strings.small.teams[match.homeTeamId];
  const awayTeamSmall = strings.small.teams[match.awayTeamId];
  const homeGoals = match.homeGoals;
  const awayGoals = match.awayGoals
  const startAt = new Date(match.startAt);
  let resultText;
  if (homeGoals === awayGoals) resultText = `Das Spiel ging ${homeGoals}:${awayGoals} unentschieden aus`;
  else if (homeGoals > awayGoals) resultText = `${homeTeamSmall} gewann das Spiel gegen ${awayTeamSmall} mit ${homeGoals}:${awayGoals}`;
  else if (homeGoals < awayGoals) resultText = `${awayTeamSmall} gewann das Spiel gegen ${homeTeamSmall} mit ${homeGoals}:${awayGoals}`;

  if (isTomorrow(startAt)) {
    document.title = `MORGEN: ${homeTeamSmall} -:- ${awayTeamSmall} | Übertragung | TV | Live Ergebnisse`;
    document.head.children.description.content = `Morgen spielt ${homeTeam} gegen ${awayTeam} um ${startAt.toLocaleTimeString('de-DE').slice(0, -3)} (${strings.medium['tournament'+match.tournamentId.toString()]}). Das Spiel wird auf ... übertragen.`;
      }
  else if (isActive(match.status)) {                                // Today + Live
    document.title = `JETZT: ${homeTeamSmall} ${homeGoals}:${awayGoals} ${awayTeamSmall} | Live Ergebnisse | Übertragung`;
    document.head.children.description.content = `Es spiel aktuell ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). Alle Tore, Torschützen und live Ergebnisse in der App. Das Spiel wird auf ... übertragen.`;
  }
  else if (isToday(startAt)) {                                      // Today + before
    document.title = `HEUTE: ${homeTeamSmall} -:- ${awayTeamSmall} | Übertragung | TV | Live Ergebnisse`;
    document.head.children.description.content = `Heute spielt ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). Alle Tore, Torschützen und live Ergebnisse in der App. Das Spiel wird auf ... übertragen.`;
  }
  else if (isToday(startAt) && isMatchEnded(match.status)) {        // Today + after
    document.title = `HEUTE: ${homeTeamSmall} ${homeGoals}:${awayGoals} ${awayTeamSmall} | Ergebnisse | Tore`;
    document.head.children.description.content = `Heute spielte ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). ${resultText}. Hier findest du alle Tore, Torschützen und Ergebnisse.`;
  }
  else if (match.status === 'scheduled') {                          // Starts in over 1 day
    document.title = `${homeTeamSmall} -:- ${awayTeamSmall} | Übertragung | TV | Live Ergebnisse`;
    document.head.children.description.content = `Am ${startAt.toLocaleString('de-DE', {weekday: 'long'})} den ${startAt.toLocaleDateString('de-DE')} um ${startAt.toLocaleTimeString('de-DE').slice(0, -3)} spielt ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). Das Spiel wird auf ... übertragen.`;
  }
  else if (isYesterday(startAt)) {                                  // Ended yesterday
    document.title = `GESTERN: ${homeTeamSmall} ${homeGoals}:${awayGoals} ${awayTeamSmall} | Ergebnisse | Tore`;
    document.head.children.description.content = `Gestern spielte ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). ${resultText}. Hier findest du alle Tore, Torschützen und Ergebnisse.`;
  }
  else if (isMatchEnded(match.status)) {                            // Ended over 1 day ago
    document.title = `${homeTeamSmall} ${homeGoals}:${awayGoals} ${awayTeamSmall} | Ergebnisse | Tore`;
    document.head.children.description.content = `Am ${startAt.toLocaleString('de-DE', {weekday: 'long'})} den ${startAt.toLocaleDateString('de-DE')} spielte ${homeTeam} gegen ${awayTeam} (${strings.medium['tournament'+match.tournamentId.toString()]}). ${resultText}. Hier findest du alle Tore, Torschützen und Ergebnisse.`;
  }

  return (
    <div id="details" style={tableStyles.container} className="safe-area-desktop-small">
      <Lazy>
        <DetailsHeader match={match} strings={strings}/>
        <DetailsEvents match={match} strings={strings}/>
        <DetailsComparison match={match} matches={matches} strings={strings}/>
        <DetailsBroadcasts match={match} strings={strings}/>
        <div style={{...tableStyles.footerView, ...styles.footerView}}><div style={tableStyles.footerText} /*maxFontSizeMultiplier={1.3}*/>{strings.medium['tournament' + match.tournamentId]}{', '}{strings.medium[match.stageStringId] || getRoundName(match.roundId, match.rounds, strings)}</div></div>
      </Lazy>
    </div>
  );
};

const styles = {
  footerView: {
    justifyContent: 'center',
  },
};

export default withErrorBoundary(Details, 'Details');
