const isMatchEnded = (status) => {
  switch (status) {
    case 'firstHalf': return true;
    case 'halfTime': return true;
    case 'secondHalf': return true;
    case 'extraTime': return true;
    case 'penaltyShootout': return true;
    case 'interrupted': return true;
    case 'suspended': return true;
    default: return false;
  }
};

export default isMatchEnded;
