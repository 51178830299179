/**
 * Get the first standing of a tournamentId, stageId
 */

const searchTournamentStageEnd = (standings, tournamentId, stageId) => { try {
  return treeSearch(standings, tournamentId, stageId, 0, standings.length-1, 0);
} catch (e) { return null; }};

const treeSearch = (standings, tournamentId, stageId, lower, upper, iterations) => {
  iterations++;                                                     // Prevent unknown endless loops
  if (iterations === 30) return null;
  if (lower > upper) return null;                                   // TournamentId not found

  const median = lower + Math.round((upper - lower) / 2);
  const currentStanding = standings[median];
  const nextStanding = standings[median+1];

  if      (tournamentId < currentStanding.tournamentId) return treeSearch(standings, tournamentId, stageId, lower, median - 1, iterations); // Go left
  else if (tournamentId > currentStanding.tournamentId) return treeSearch(standings, tournamentId, stageId, median + 1, upper, iterations); // Go right
  else if (tournamentId===currentStanding.tournamentId) {           // You need to check the stageId
    if      (stageId < currentStanding.stageId) return treeSearch(standings, tournamentId, stageId, lower, median - 1, iterations);         // Go left
    else if (stageId > currentStanding.stageId) return treeSearch(standings, tournamentId, stageId, median + 1, upper, iterations);         // Go right
    else if (stageId===currentStanding.stageId && (stageId!==nextStanding?.stageId || tournamentId!==nextStanding?.tournamentId)) return median; // The "?." is just to not crash on the first and last match of standings
    else if (stageId===currentStanding.stageId) return treeSearch(standings, tournamentId, stageId, median + 1, upper, iterations);         // Go right
  }
};

export default searchTournamentStageEnd;
