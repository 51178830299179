const isDate = (date) => {
  return (
    date &&                                                         // Test if it is not null, undefined, ... 
    Object.prototype.toString.call(date) === '[object Date]' &&     // Test if it is a date object
    !isNaN(date)                                                    // Test if it is a valid date. E. g. new Date('abc'), new Date(undefined) is invalid
  );
}


export default isDate;
