import React from 'react';
import {Link} from 'react-router-dom';
import Countdown from './Countdown.js';
import RowViewChevron from './RowViewChevron.js';
import getDatePath from '../../helper/getDatePath.js';
import tableStyles from './../../styles/tableStyles.js';

const NextMatchesCountdown = ({matches, tournamentId, to, left}) => {
  console.log('NextMatchesCountdown.render()');
  let nextMatches = matches
    .filter((match) =>
      match.status === 'scheduled' &&
      tournamentId ? match.tournamentId === tournamentId : true &&
      (new Date(match.startAt)).getTime() < (Date.now() + 1000*60*60*24*14) &&  // Just take matches that are in the next 14 days for performance reasons
      (new Date(match.startAt)).getTime() > Date.now())
    .sort((a, b) => (new Date(a.startAt)).getTime() - (new Date(b.startAt)).getTime())
    .filter((match, index, matches2) => match.startAt === matches2[0].startAt); // filter all elements that are equal to the first elements startAt date
  
  if (nextMatches.length === 0) return null;
  const nextMatchDate = new Date(nextMatches?.[0].startAt);
  
  return (
    <>
      <Link to={to || getDatePath(nextMatchDate)} style={{...tableStyles.rowViewFirstLast}}>
        <div style={{...tableStyles.rowText, ...styles.rowText, ...left && {textAlign: 'left'}}}>Nächstes Spiel: <Countdown date={nextMatchDate}/></div>
        <RowViewChevron/>
      </Link>
      <div style={tableStyles.footerViewEmpty}/>
    </>
  );
};

const styles = {
  rowText: {
    flexGrow: 1,
    textAlign: 'center',
    paddingRight: 16,
  },
}

export default NextMatchesCountdown;
