/**
 * It's better to use this HOC instead of ErrorBoundary component directly.
 * It catches errors of 'Component' just in the whole render function. E. g.:
 *
 * const Component = () => {
 *   ...                                                           // <= Here a simple ErrorBoundary doesn't catch the error
 *   return (
 *     <ErrorBoundary>
 *     ...                                                         // <= Here a simple ErrorBoundary does catch the error
 *     </ErrorBoundary>
 *   );
 * }
 *
 * Error boundaries do not catch errors for:
 * - Event handlers
 * - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 * - Server side rendering
 * - Errors thrown in the error boundary itself (rather than its children)
 *
 * Error boundaries do catch errors for:
 * - during rendering, in class lifecycle methods, and in constructors
 *
 * How to test
 * - use hello.world
 * - use array in style e. g. <div style={[...]}/> (only in web)
 *
 * Other
 * - In the past it seemed to not work in React Native. This was wrong. Everything works fine in RN.
 */

import React from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import tableStyles from './../../styles/tableStyles';

const withErrorBoundary_ = (component, componentName) => {
  return withErrorBoundary(component, {
    fallback: (
      <div style={tableStyles.container}>
        <div style={tableStyles.headerViewEmpty}/>
        <div style={tableStyles.rowViewFirstLast}>
          <div style={tableStyles.rowText}>Error in {componentName}</div>
        </div>
      </div>
    ),
    onError: (error) => console.log(componentName + 'Error', error), // E.g. NewsScreenError
  });
};

export default withErrorBoundary_;
