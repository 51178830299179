import React from 'react';
import Icon from './../Icon.js';
import Touch from './../Touch.js';
import tableStyles from './../../styles/tableStyles.js';
import getRoundName from './../../helper/getRoundName.js';
import colors from './../../colors.js';

const MatchHeader = ({match, strings, arrow, onPress, onRoundPress}) => {
  console.log('MatchHeader.render()');
  const stageTitle = strings.medium[match.stageStringId];
  const roundTitle = getRoundName(match.roundId, match.rounds, strings);
  return (
    <div style={tableStyles.headerView}>
      <Touch style={styles.left}>
        <div style={tableStyles.headerText} className="ellipsis" /* ellipsis not working */>
          {stageTitle || roundTitle}
        </div>
        {/* <Icon androidName="menu-down" iosName="caret-down-outline" androidSize={20} iosSize={12} color={colors.primary}/> */}
      </Touch>
      {
        arrow
          ? (
            <Touch style={styles.arrow} onPress={onPress} iosComponent="TouchableOpacity">
              <Icon androidName="chevron-up" iosName="chevron-up-outline" androidSize={33} iosSize={30} color={colors.primary}/>
            </Touch>
          )
          : null
      }
      <div style={styles.right}>
        <div style={tableStyles.headerText} className="ellipsis" /* ellipsis not working */>
          {stageTitle && roundTitle}
        </div>
      </div>
    </div>
  );
};

const styles = {
  left: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 1,
    flexGrow: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  arrow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 80,
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 1,
    flexGrow: 1,
    alignItems: 'flex-end',
  },
};

export default MatchHeader;
