import React from 'react';
import Standing from './Standing.js';
import StandingHeader from './StandingHeader.js';
import StandingFooter from './StandingFooter.js';
import StandingSection from './StandingSection.js';
import Lazy from './../Lazy.js';
import tableStyles from './../../styles/tableStyles.js';
import searchTournamentStageStart from './searchTournamentStageStart.js';
import searchTournamentStageEnd from './searchTournamentStageEnd.js';

const Standings = ({standings, strings, tournamentId, stageId, initialExplanation}) => {
  console.log('Standings.render()');

  const rows = [];
  const tournamentStageStart = searchTournamentStageStart(standings, tournamentId, stageId);
  const tournamentStageEnd = searchTournamentStageEnd(standings, tournamentId, stageId);
  if (tournamentStageStart === null || tournamentStageEnd === null) {
    return null;
  }
  for (let i = tournamentStageStart; i <= tournamentStageEnd; i++) {
    const standing = standings[i];
    const previousStanding = standings[i-1];
    const nextStanding = standings[i+1];
    const tournamentWillChange = nextStanding?.tournamentId !== standing.tournamentId;
    const tournamentDidChange = previousStanding?.tournamentId !== standing.tournamentId; // Hint: You also have to check tournamentId. Reason: The current standing could be t101 with group undefined and the previous standing could be t50 with group undefined. So no group change = bad.
    const stageWillChange = nextStanding?.stageId !== standing.stageId;
    const stageDidChange = previousStanding?.stageId !== standing.stageId;
    const groupWillChange = nextStanding?.group !== standing.group;
    const groupDidChange = previousStanding?.group !== standing.group;

    /* StandingHeader: Table or Group A */
    if (tournamentDidChange || stageDidChange || groupDidChange)
      rows.push(<StandingHeader key={'StandingHeader' + standing.standingId}>{standing.group ? strings.medium.group + ' ' + standing.group : strings.medium.table}</StandingHeader>);

    /* StandingSection: # Pl W D L Goals +/- P */
    if (tournamentDidChange || stageDidChange || groupDidChange)
      rows.push(<StandingSection key={'StandingSection' + standing.standingId} strings={strings}/>);

    /* StandingLine: ----- */
    if (!tournamentDidChange && !stageDidChange && !groupDidChange)
      rows.push(<div key={'StandingLine' + standing.standingId} style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>);

    rows.push(<Standing key={standing.standingId} standing={standing} strings={strings} last={tournamentWillChange || stageWillChange || groupWillChange}/>);

    if (tournamentWillChange || stageWillChange)
      rows.push(<StandingFooter key={'StandingFooter' + standing.standingId} standings={standings} strings={strings} tournamentStageStart={tournamentStageStart} tournamentStageEnd={tournamentStageEnd} initialExplanation={initialExplanation}/>);
  }
  return (
    <Lazy>
      <div id="standings">
        {rows}
      </div>
    </Lazy>
  );
};

export default Standings;
