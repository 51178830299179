import React from 'react';
import Title from './Title.js';

const NotFound = () => {
  console.log('NotFound.render()');
  return (
    <Title>Seite nicht gefunden</Title>
  );
};

export default NotFound;
