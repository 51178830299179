import React from 'react';
import Match from './../match/Match';
import Touch from './../Touch';
import tableStyles from './../../styles/tableStyles';
import colors from './../../colors';

const TeamPastMatches = ({teamId, teamStatistics, strings}) => {
  console.log('TeamPastMatches.render()');
  const [more, setMore] = React.useState(false);
  return (
    <>
      <div style={tableStyles.headerView}>
        <div style={tableStyles.headerText}>{strings.medium.pastMatches}</div>
      </div>
      {
        teamStatistics.pastMatches.slice(more ? -100 : -5).map((match, index, pastMatches) => {
          return (
            <React.Fragment key={match.matchId}>
              <Match match={match} strings={strings} detailsTeamId={teamId} first={index === 0} last={index === pastMatches.length-1}/>
              {index !== pastMatches.length-1 && <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>}
            </React.Fragment>
          );
        })
      }
      <div style={{...tableStyles.footerView, ...styles.footer}}>
        <Touch style={styles.moreTouch} onPress={() => setMore(!more)}>
          <div style={styles.moreText}>{more ? strings.medium.less : strings.medium.more2}</div>
        </Touch>
      </div>
    </>
  );
};

const styles = {
  footer: {
    justifyContent: 'center',
  },
  moreTouch: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  moreText: {
    color: colors.primary,
  },
};

export default TeamPastMatches;
