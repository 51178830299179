/**
 * Minimal View for all matches of a tournament today/yesterday/tomorrow
 * It does not have to be very fancy. It's just a SEO landing page.
 */

import React from 'react';
import {Link} from 'react-router-dom';
import Lazy from './Lazy.js';
import Title from './Title.js';
import Match from './match/Match.js';
import NextMatchesCountdown from './common/NextMatchesCountdown.js';
import RowViewChevron from './common/RowViewChevron';
import withErrorBoundary from './common/withErrorBoundary.js';
import searchTournamentStart from './match/searchTournamentStart';
import searchTournamentEnd from './match/searchTournamentEnd';
import isSameDate from './../helper/isSameDate';
import tournamentNames from './../helper/tournamentNames.js';
import tournamentPaths from './../helper/tournamentPaths.js';
import tableStyles from './../styles/tableStyles';

const TournamentCalendar = ({day, matches, strings, tournamentId}) => {
  console.log('TournamentCalendar.render()');

  const dayUpper = day === 'yesterday' ? 'Gestern' : day === 'tomorrow' ? 'Morgen' : 'Heute';
  const dayLower = dayUpper.toLowerCase();

  const tournamentName = tournamentNames[tournamentId];
  const tournamentPath = tournamentPaths[tournamentId];

  const tournamentStart = searchTournamentStart(matches, tournamentId);
  const tournamentEnd = searchTournamentEnd(matches, tournamentId);

  const date = new Date();
  date.setDate(date.getDate() + (day === 'tomorrow' ? 1 : day === 'yesterday' ? -1 : 0));

  let foundMatches = matches
    .slice(tournamentStart, tournamentEnd+1)
    .filter((match) => isSameDate(new Date(match.startAt), date));

  /* Head Title */
  document.title = 
    day === 'today' ? `${tournamentName} Heute: Alle Fußball Spiele, TV Übertragungen und Live Ergebnisse` :
    day === 'tomorrow' ? `${tournamentName} Morgen: Alle Fußball Spiele, alle TV Übertragungen` :
    `${tournamentName} Gestern: Alle Fußball Ergebnisse, alle Tore`;

  /* Head Meta Description */
  document.head.children.description.content =
    day === 'today' ? `Wer spielt heute? Wer überträgt die ${tournamentName} Spiele im TV? Alle ${tournamentName} Spiele, alle Ergebnisse Live inkl. Tabelle` :
    day === 'tomorrow' ? `Wer spielt morgen und wo wird das ${tournamentName} Spiel im TV übertragen? Alle ${tournamentName} Spiele, alle TV Sender.` :
    `Alle Fußball Ergebnisse, aktuelle Tabellen und ${tournamentName} Spiele von gestern.`;

  return (
    <div id="tournament-calendar" className="safe-area-desktop-small">
      <Lazy>
        <Title>{tournamentName} {dayLower}</Title>
        <div style={tableStyles.headerView}><div style={tableStyles.headerText}>
          Alle Spiele am {date.getDate()}.{date.getMonth()+1}.{date.getFullYear()}
        </div></div>
        {
          foundMatches.length > 0
          ? (
            foundMatches.map((match, index) => (
              <>
                <Match key={match.matchId} match={match} strings={strings} first={index === 0} last={index === foundMatches.length-1}/>
                {
                  (index !== foundMatches.length-1) 
                  ? <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>
                  : null
                }
              </>
            ))
          )
          : <>
              <div style={tableStyles.rowViewFirstLast}><div style={tableStyles.rowText}>ℹ️ {dayUpper} keine {tournamentName} Spiele</div></div>
              <div style={tableStyles.footerViewEmpty}/>
              <NextMatchesCountdown matches={matches} tournamentId={tournamentId} to={tournamentPath} left={true}/>
            </>
        }
        <div style={tableStyles.footerViewEmpty}/>
        {
          (day === 'yesterday' || day === 'tomorrow') && (
            <>
              <Link to={tournamentPath + '/heute'} style={tableStyles.rowViewFirstLast}>
                <div style={tableStyles.rowText}>
                  {tournamentName} heute
                </div>
                <RowViewChevron/>
              </Link>
              <div style={tableStyles.footerViewEmpty}/>
            </>
          )
        }
        {
          (day === 'today' || day === 'tomorrow') && (
            <>
              <Link to={tournamentPath + '/gestern'} style={tableStyles.rowViewFirstLast}>
                <div style={tableStyles.rowText}>
                  {tournamentName} gestern
                </div>
                <RowViewChevron/>
              </Link>
              <div style={tableStyles.footerViewEmpty}/>
            </>
          )
        }
        {
          (day === 'today' || day === 'yesterday') && (
            <>
              <Link to={tournamentPath + '/morgen'} style={tableStyles.rowViewFirstLast}>
                <div style={tableStyles.rowText}>
                  {tournamentName} morgen
                </div>
                <RowViewChevron/>
              </Link>
              <div style={tableStyles.footerViewEmpty}/>
            </>
          )
        }
        <Link to={tournamentPath} style={tableStyles.rowViewFirstLast}>
          <div style={tableStyles.rowText}>
            Alle {tournamentName} Spiele und Tabelle
          </div>
          <RowViewChevron/>
        </Link>
        <div style={tableStyles.footerViewEmpty}/>
        <Link to={`/${dayLower}`} style={tableStyles.rowViewFirstLast}>
          <div style={tableStyles.rowText}>
            Alle Spiele aller Ligen {dayLower}
          </div>
          <RowViewChevron/>
        </Link>
        <div style={tableStyles.footerViewEmpty}/>
      </Lazy>
    </div>
  );
};

export default withErrorBoundary(TournamentCalendar, 'TournamentCalendar');
