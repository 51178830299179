const isMatchEnded = (status) => {
  switch (status) {
    case 'ended': return true;
    case 'endedExtraTime': return true;
    case 'endedPenaltyShootout': return true;
    case 'abandoned': return true;
    default: return false;
  }
};

export default isMatchEnded;
