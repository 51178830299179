import React from 'react';
import useWindowDimensions from './../../helper/useWindowDimensions.js';
import standingStyles from './standingStyles.js';
import tableStyles from './../../styles/tableStyles.js';
import colors from './../../colors.js';

const StandingSection = ({strings}) => {
  console.log('StandingSection.render()');
  const {width} = useWindowDimensions();
  return (
    <div style={tableStyles.sectionViewFirst}>
      { width >= 360 && <div style={standingStyles.resultText}></div> }
      <div style={standingStyles.rank} /*numberOfLines={1}*/ /*adjustsFontSizeToFit={true}*/>#</div>
      <div style={standingStyles.team}/>
      <div style={standingStyles.played} /*numberOfLines={1}*/ /*adjustsFontSizeToFit={true}*/>{strings.shortcut.played}</div>
      <div style={standingStyles.won} /*numberOfLines={1}*/ /*adjustsFontSizeToFit={true}*/>{strings.shortcut.won}</div>
      <div style={standingStyles.drawn} /*numberOfLines={1}*/ /*adjustsFontSizeToFit={true}*/>{strings.shortcut.drawn}</div>
      <div style={standingStyles.lost} /*numberOfLines={1}*/ /*adjustsFontSizeToFit={true}*/>{strings.shortcut.lost}</div>
      {/* Placeholder goalsFor */}
      <div style={styles.goals} /*numberOfLines={1}*/ /*adjustsFontSizeToFit={true}*/>{strings.medium.goals}</div>
      {/* Placeholder goalsAgainst */}
      <div style={standingStyles.goalDifference} /*numberOfLines={1}*/ /*adjustsFontSizeToFit={true}*/>{strings.small.goalDifference}</div>
      <div style={standingStyles.points} /*numberOfLines={1}*/ /*adjustsFontSizeToFit={true}*/>{strings.small.points}</div>
    </div>
  );
};

const styles = {
  goals: {                                                          // goalsFor + goalsSeparator + goalsAgainst
    flexBasis: 61,                                                  // 28 + 5 + 28
    flexShrink: 0,                                                  // 0 + 0 + 0
    flexGrow: 0,                                                    // 0 + 0 + 0
    textAlign: 'center',
    fontSize: 14,
    color: colors.textSecondary,
  },
};

export default StandingSection;
