import React from 'react';
import Touch from './../Touch.js';
import tableStyles from './../../styles/tableStyles.js';
import colors from './../../colors.js';

const StandingFooter = ({standings, strings, tournamentStageStart, tournamentStageEnd, initialExplanation = false}) => {
  console.log('StandingFooter.render()');
  const [explanation, setExplanation] = React.useState(initialExplanation);
  return (
    <>
      <div style={{...tableStyles.footerView, ...styles.footerView}}>
        <Touch style={styles.explanationButton} onPress={() => setExplanation(!explanation)} iosComponent="TouchableOpacity">
          <div style={styles.explanationButtonText}>
            {explanation ? strings.medium.close : strings.medium.explanation}
          </div>
        </Touch>
      </div>
      {explanation && (<>
        <div style={tableStyles.rowViewFirst}><div style={styles.abbreviationText}>{strings.shortcut.played}</div><div style={styles.explanationText}>{strings.medium.played}</div></div>
        <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>
        <div style={tableStyles.rowView}><div style={styles.abbreviationText}>{strings.shortcut.won}</div><div style={styles.explanationText}>{strings.medium.won}</div></div>
        <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>
        <div style={tableStyles.rowView}><div style={styles.abbreviationText}>{strings.shortcut.drawn}</div><div style={styles.explanationText}>{strings.medium.drawn}</div></div>
        <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>
        <div style={tableStyles.rowView}><div style={styles.abbreviationText}>{strings.shortcut.lost}</div><div style={styles.explanationText}>{strings.medium.lost}</div></div>
        <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>
        <div style={tableStyles.rowView}><div style={styles.abbreviationText}>{strings.shortcut.goalDifference}</div><div style={styles.explanationText}>{strings.medium.goalDifference}</div></div>
        <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>
        <div style={tableStyles.rowViewLast}><div style={styles.abbreviationText}>{strings.shortcut.points}</div><div style={styles.explanationText}>{strings.medium.points}</div></div>
        <div style={tableStyles.footerViewEmpty}/>
        {
          (() => {
            const resultStringIds = [];
            for (let i = tournamentStageStart; i <= tournamentStageEnd; i++) {
              const standing = standings[i];
              if (!resultStringIds.includes(standing.resultStringId) && standing.resultStringId)
                resultStringIds.push(standing.resultStringId);
            }
            return resultStringIds.map((resultStringId, index) => {
              const first = index === 0;
              const last = index === resultStringIds.length - 1;
              return (
                <div key={resultStringId}>
                  <div style={first && last ? tableStyles.rowViewFirstLast : first ? tableStyles.rowViewFirst : last ? tableStyles.rowViewLast : tableStyles.rowView}>
                    <div style={styles.abbreviationText} /*numberOfLines={1}*/>{strings.shortcut[resultStringId]}</div>
                    <div style={styles.explanationText} /*numberOfLines={1}*/>
                      {strings.medium[resultStringId]}
                    </div>
                  </div>
                  {!last && <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>}
                </div>
              );
            });
          })()
        }
      </>)}
    </>
  );
};

const styles = {
  footerView: {
    justifyContent: 'space-between',
  },
  explanationButton: {
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  explanationButtonText: {
    fontSize: 13,                                                   // iOS 15 | table rounded footer
    color: colors.primary,
  },
  abbreviationText: {
    flexBasis: 1,
    flexGrow: 1,
  },
  explanationText: {
    flexBasis: 1,
    flexGrow: 1,
  },
};

export default StandingFooter;
