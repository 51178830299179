import React from 'react';
import {Link} from 'react-router-dom';
import tournamentPaths from './../../helper/tournamentPaths.js';
import isToday from './../../helper/isToday.js';
import isYesterday from './../../helper/isYesterday.js';
import isTomorrow from './../../helper/isTomorrow.js';
import getWeekdayStringId from './../../helper/getWeekdayStringId.js';
import tableStyles from './../../styles/tableStyles.js';

const MatchSection = ({match, startAt, strings, first}) => {
  console.log('MatchSection.render()');
  return (
    <div style={first ? tableStyles.sectionViewFirst : tableStyles.sectionView}>
      <div style={isToday(startAt) ? styles.sectionTodayText : tableStyles.sectionText}>
        {
          isToday(startAt) ? <Link to={tournamentPaths[match.tournamentId] + '/heute'}>{strings.medium.today} 🔥</Link> :
          isYesterday(startAt) ? <Link to={tournamentPaths[match.tournamentId] + '/gestern'}>{strings.medium.yesterday}</Link> :
          isTomorrow(startAt) ? <Link to={tournamentPaths[match.tournamentId] + '/morgen'}>{strings.medium.tomorrow}</Link> :
          strings.medium[getWeekdayStringId(startAt)]
        }
      </div>
      <div style={styles.dateText} /*maxFontSizeMultiplier={1.5}*/>
        {startAt.getDate()}.{startAt.getMonth()+1}.
      </div>
    </div>
  );
};

const styles = {
  sectionTodayText: {
    fontSize: 15,                                                   // iOS 15 | table section | default 17px
    color: '#f44336',                                               // iOS 15 | table section | Set colors explicit. This prevents a RN bug where text disappears if the color state changes. E. g. from red to default. Better: from red to black.
    fontWeight: 'bold',
  },
  dateText: {
    flexBasis: 1,
    flexGrow: 1,
    fontSize: 14,
    textAlign: 'right',
  },
};

export default MatchSection;
