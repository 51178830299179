/**
 * I've tested an asymmetric style twice.
 * This symmetric style just looks better.
 * See also OneNote.
 */

import React from 'react';
import Icon from './../Icon.js';
import tableStyles from './../../styles/tableStyles.js';

const DetailsEvent = ({strings, event, first, last}) => {
  console.log('DetailsEvent.render()');

  const icon = (
    event.type === 'goal' ? <Icon androidName="soccer" color="black" size={26}/> :
    event.type === 'yellow' ? <Icon androidName="cards" color="#ffeb3b" size={26}/> :
    event.type === 'red' ? <Icon androidName="cards" color="#f44336" size={26}/> :
    event.type === 'yellowRed' ? <><Icon androidName="cards" color="#ffeb3b" size={26} style={styles.yellowRed}/><Icon androidName="cards" color="#f44336" size={26}/></> :
    event.type === 'substitution' ? <Icon androidName="swap-horizontal-bold" color="grey" size={26}/> :
    null
  );

  return (
    <div style={{...(first && last ? tableStyles.rowViewFirstLast : first ? tableStyles.rowViewFirst : last ? tableStyles.rowViewLast : tableStyles.rowView), ...styles.container}}>
      <div style={styles.left}>
        {
          event.team === 'home'
          ? (
            <>
              {event.stringId && <div style={styles.stringId}>{strings.medium[event.stringId]}</div>}
              {event.player2 && <div style={styles.player2} className="ellipsis">({event.player2})</div>}
              <div style={styles.player}>{event.player}</div>
              <div style={styles.minute}>{event.minute}'</div>
            </>
          ) : null
        }
      </div>
      <div style={styles.icon}>
        {icon}
      </div>
      <div style={styles.right}>
        {
          event.team === 'away'
          ? (
            <>
              <div style={styles.minute}>{event.minute}'</div>
              <div style={styles.player} className="ellipsis">{event.player}</div>
              {event.player2 && <div style={styles.player2}>({event.player2})</div>}
              {event.stringId && <div style={styles.stringId}>{strings.medium[event.stringId]}</div>}
            </>
          ) : null
        }
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: 44,
  },
  left: {
    display: 'flex',
    flexBasis: 1,
    flexShrink: 1,
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap-reverse',
    justifyContent: 'flex-end',
  },
  right: {
    display: 'flex',
    flexBasis: 1,
    flexShrink: 1,
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap-reverse',
  },
  minute: {
    color: 'grey',
  },
  player: {
    marginLeft: '6%',
    marginRight: '6%',
  },
  player2: {                                                        // Use flexShrink: 1 + numberOfLines={1} to use eclipse
    color: 'grey',
    fontSize: 12,
  },
  stringId: {
    color: 'grey',
    fontSize: 12,
    marginRight: 2,
    marginLeft: 2,
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 60,
    flexShrink: 0,
    alignItems: 'center',
  },
  yellowRed: {
    marginBottom: -22,
  },
};

export default DetailsEvent;
