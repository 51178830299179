import React from 'react';
import Icon from './../Icon.js';
import colors from './../../colors.js';

const RowViewChevron = () => {
  console.log('RowViewChevron.render()');
  return (
    <Icon
      androidName="chevron-right"
      iosName="chevron-forward-outline"
      size={33}
      color={colors.primary}
      style={styles.chevron}
    />
  );
};

const styles = {
  chevron: {
    position: 'absolute',
    right: -2,
  },
}

export default RowViewChevron;
