/**
 * Performance hints:
 *   - Using for instead of matches.filter is only ~25% faster (3ms vs 4ms, 20k iterations)
 *   - Subsequent calculations have no impact on the performance and are neglected (small amount of iterations)
 */

import isMatchEnded from './../helper/isMatchEnded.js';

const getTeamStatistics = (teamId, matches) => {
  const foundMatches = matches
    .filter((match) => match.homeTeamId === teamId || match.awayTeamId === teamId)
    .sort((a, b) => {
      const startAt1 = new Date(a.startAt);
      const startAt2 = new Date(b.startAt);
      if (startAt1 > startAt2) return 1;
      else if (startAt1 < startAt2) return -1;
      else return 0;
    });
  const pastMatches = foundMatches.filter((match) => isMatchEnded(match.status));
  const nextMatches = foundMatches.filter((match) => !isMatchEnded(match.status));
  const won = pastMatches.reduce((result, match) => {
    if (match.homeTeamId === teamId && match.homeGoals > match.awayGoals) return result + 1;
    if (match.awayTeamId === teamId && match.homeGoals < match.awayGoals) return result + 1;
    return result;
  }, 0);
  const drawn = pastMatches.reduce((result, match) => {
    if (match.homeGoals === match.awayGoals) return result + 1;
    return result;
  }, 0);
  const lost = pastMatches.reduce((result, match) => {
    if (match.homeTeamId === teamId && match.homeGoals < match.awayGoals) return result + 1;
    if (match.awayTeamId === teamId && match.homeGoals > match.awayGoals) return result + 1;
    return result;
  }, 0);
  return {
    pastMatches,
    nextMatches,
    won,
    lost,
    drawn,
    wonPercentage: Math.round(won / pastMatches.length * 100) || 0,
    drawnPercentage: Math.round(drawn / pastMatches.length * 100) || 0,
    lostPercentage: Math.round(lost / pastMatches.length * 100) || 0,
  };
};

export default getTeamStatistics;
